import React from 'react';
import { Flex } from '@withjoy/joykit';

interface WrapperProps
  extends Readonly<{
    bigger?: boolean;
  }> {}

export const Wrapper: React.FC<WrapperProps> = ({ bigger = false, children }) => {
  return (
    <Flex alignItems="center" justifyContent="center" marginTop={[0, null, '36px']}>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" width={['100%', null, `${bigger ? '5' : '3'}75px`]}>
        {children}
      </Flex>
    </Flex>
  );
};
