import { useState, useEffect, useMemo } from 'react';
import { FormikProps } from 'formik';
import { VisualType } from './components/Switch/Switch';
import { withWindow } from '@shared/utils/withWindow';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { ContactCollectionEventFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useContactCollectorTelemetry } from '@apps/contactCollector/ContactCollector.telemetry';
import { TranslateFunction } from '@locales/generated';
import { useQueryParamHelper } from '@shared/core/queryString';

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const useEditorController = (
  eventHandle: string,
  eventByName: Maybe<ContactCollectionEventFragment>,
  formik: FormikProps<ContactCollectorInputFields>,
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { getValueString } = useQueryParamHelper();
  const isUpdating = getValueString('modify') === 'true';
  const { createOrUpdateMagicLinkClicked } = useContactCollectorTelemetry();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  const placeHolders: { [n: number]: TranslateFunction } = useMemo(
    () => ({
      0: tAdmin.messagePlaceholder0,
      1: tAdmin.messagePlaceholder1,
      2: tAdmin.messagePlaceholder2,
      3: tAdmin.messagePlaceholder3,
      4: tAdmin.messagePlaceholder4,
      5: tAdmin.messagePlaceholder5,
      6: tAdmin.messagePlaceholder6,
      7: tAdmin.messagePlaceholder7
    }),
    [tAdmin]
  );

  const info = eventByName?.info;
  const names = (info?.ownerFirstName && info?.fianceeFirstName ? `${info?.ownerFirstName} & ${info?.fianceeFirstName}` : info?.ownerFirstName) || 'The Couple';
  const date = info?.dateV0918?.dateString;

  const [selectedVisualType, setSelectedVisualType] = useState<VisualType>(VisualType.ANIMATION);
  const [placeHolder, setPlaceHolder] = useState<string>();

  const { mediaSource, mediaType, message, websiteLinkEnabled, eventDateDisplayEnabled } = formik.values;

  useEffect(() => setSelectedVisualType(mediaType), [mediaType]);

  const handleOnGoToSettingsClick = () => {
    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/settings`, '_blank');
    });
  };

  const handleOnGoToSecuritySettingsClick = () => {
    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/settings/security`, '_blank');
    });
  };

  const getRandomPlaceHolder = () => {
    const position = getRandomInt(8);

    return placeHolders[position]({ names: names });
  };

  const handleOnCreateMagicLinkClick = () => {
    formik.handleSubmit();
    setOpenDialog?.(false);
    createOrUpdateMagicLinkClicked({
      eventHandle,
      visual: selectedVisualType === VisualType.IMAGE ? 'event photo' : 'animation',
      animation: selectedVisualType === VisualType.IMAGE ? 'none' : mediaSource,
      message,
      linkToWebsite: websiteLinkEnabled,
      displayDate: eventDateDisplayEnabled,
      eventDate: date ?? '',
      operation: isUpdating ? 'update' : 'create'
    });
  };

  const handleOnRestartClick = () => {
    const newPlaceHolder = getRandomPlaceHolder();
    setPlaceHolder(newPlaceHolder);
    formik.setFieldValue('message', newPlaceHolder);
  };

  return {
    selectedVisualType,
    setSelectedVisualType,
    handleOnGoToSettingsClick,
    handleOnGoToSecuritySettingsClick,
    handleOnCreateMagicLinkClick,
    handleOnRestartClick,
    mediaSource,
    message,
    websiteLinkEnabled,
    eventDateDisplayEnabled,
    pages: eventByName?.pages || [],
    privateEventDate: !!eventByName?.settings?.requirePasswordForEventDate,
    date,
    names,
    tAdmin,
    placeHolder,
    isUpdating
  };
};
