import { useEffect } from 'react';
import { GetContactCollectionByMagicLinkQuery, useGetDataForContactCollectionGuestQuery } from '@graphql/generated';
import useLoadGoogleFont from '@apps/saveTheDate/hooks/useLoadGoogleFont';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useContactCollectorGuestTelemetryTelemetry } from '../../ContactCollectorGuest.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { contactCollectorGuestRoutePaths } from '../..';
import { Context } from '../../ContactCollectorGuest.controller';

const removeTimeFromDate = (date: string) => {
  if (!date) {
    return date;
  }
  const array = date.split(' ');
  if (date.length < 4) {
    return date;
  }
  return [array[0], array[1], array[2], array[3]].join(' ');
};

export const useLandingController = (setContext: React.Dispatch<React.SetStateAction<Context>>, data?: GetContactCollectionByMagicLinkQuery) => {
  const routeHelpers = useRouterHelper();
  const isMobile = useIsMobileScreen();
  const { trackError, landingPageViewed, buttonInteracted } = useContactCollectorGuestTelemetryTelemetry();
  const { data: queryData, loading, error } = useGetDataForContactCollectionGuestQuery({
    batchMode: 'fast',
    ssr: false,
    variables: {
      eventId: data?.getContactCollectionByMagicLink?.eventId!
    },
    skip: !data?.getContactCollectionByMagicLink?.eventId,
    onError: error => trackError('Query', error)
  });

  const fontFamily = queryData?.eventById?.eventDesign?.font?.fontFamily ?? 'PT Serif';
  const fontTextTransform = queryData?.eventById?.eventDesign?.font?.textTransform ?? 'none';
  useLoadGoogleFont([`${fontFamily}:400`]);
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');
  const info = queryData?.eventById?.info;
  const eventDisplayName = info?.eventDisplayName || tAdmin.theCouple();
  const date = removeTimeFromDate(info?.dateV0918?.dateString ?? '');
  const coupleNames = (info?.ownerFirstName && info?.fianceeFirstName ? `${info?.ownerFirstName} & ${info?.fianceeFirstName}` : info?.ownerFirstName) || tAdmin.theCouple();
  const website = queryData?.eventById?.website ?? '';

  useEffect(() => {
    if (!loading) {
      landingPageViewed({ eventHandle: website });
      setContext({
        coupleNames,
        eventDisplayName,
        date,
        eventDateDisplayEnabled: !!data?.getContactCollectionByMagicLink?.eventDateDisplayEnabled,
        website,
        websiteLinkEnabled: !!data?.getContactCollectionByMagicLink?.websiteLinkEnabled,
        fontFamily,
        fontTextTransform,
        mediaType: data?.getContactCollectionByMagicLink?.mediaType ?? '',
        mediaSource: data?.getContactCollectionByMagicLink?.mediaSource ?? '',
        firebaseId: queryData?.eventById?.firebaseId ?? ''
      });
    }
  }, [
    coupleNames,
    data?.getContactCollectionByMagicLink?.eventDateDisplayEnabled,
    data?.getContactCollectionByMagicLink?.websiteLinkEnabled,
    date,
    eventDisplayName,
    landingPageViewed,
    loading,
    setContext,
    website,
    fontFamily,
    fontTextTransform,
    data?.getContactCollectionByMagicLink?.mediaType,
    data?.getContactCollectionByMagicLink?.mediaSource,
    queryData?.eventById?.firebaseId
  ]);

  const handleOnVisitEventWebsiteClick = () => {
    buttonInteracted({ label: 'VisitEventWebsite' });
    withWindow(() => {
      window.open(`${window.location.origin}/${website}`, '_blank');
    });
  };

  const handleOnProvideDetailsClick = () => {
    buttonInteracted({ label: 'ProvideDetails' });
    routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.form.path);
  };

  return {
    fontFamily,
    fontTextTransform,
    tAdmin,
    eventDisplayName,
    coupleNames,
    date,
    loading,
    error,
    handleOnProvideDetailsClick,
    handleOnVisitEventWebsiteClick,
    isMobile
  };
};
