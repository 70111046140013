import React from 'react';
import { FormikProps } from 'formik';
import { ContactInputFields, Context } from '../../ContactCollectorGuest.controller';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import { Wrapper, Details, Footer } from '../components';
import { useConfirmationController } from './Confirmation.controller';
import { outlinedButtonOverrides } from '@apps/saveTheDate/components/Shared.styles';
import { Profile, Phone, Email, Shipping } from '@withjoy/joykit/icons';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

interface ConfirmationProps
  extends Readonly<{
    formik: FormikProps<ContactInputFields>;
    context: Context;
    isMutationLoading: boolean;
    hasMutationError: boolean;
  }> {}

export const Confirmation: React.FC<ConfirmationProps> = ({ formik, context: { coupleNames }, isMutationLoading, hasMutationError }) => {
  const { tAdmin, handleOnModifyDetailsClick, handleOnLooksGoodClick } = useConfirmationController(formik);

  if (isMutationLoading) return <JoyLogoLoader loaderKey="contact-collector-address-confirm" />;

  return (
    <Wrapper>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX="24px" width="100%">
        <TextV2 typographyVariant="hed6" marginTop={['24px', null, '4px']} textAlign="center">
          {tAdmin.looksRight()}
        </TextV2>
        <Details coupleNames={coupleNames} />
        <TextV2 typographyVariant="hed4" marginTop="24px" marginBottom="12px" width="100%">
          {tAdmin.contactInfo()}
        </TextV2>
        <Flex alignItems="center" justifyContent="flex-start" marginBottom="8px" width="100%">
          <Profile color="mono12" />
          <TextV2 color="mono12" typographyVariant="body2" marginLeft="8px">{`${formik.values.firstName} ${formik.values.lastName}`}</TextV2>
        </Flex>
        {formik.values.phoneNumber && (
          <Flex alignItems="center" justifyContent="flex-start" marginBottom="8px" width="100%">
            <Phone color="mono12" />
            <TextV2 color="mono12" typographyVariant="body2" marginLeft="8px">
              {formik.values.phoneNumber}
            </TextV2>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="flex-start" width="100%">
          <Email color="mono12" />
          <TextV2 color="mono12" typographyVariant="body2" marginLeft="8px">
            {formik.values.email}
          </TextV2>
        </Flex>
        <TextV2 typographyVariant="hed4" marginTop="24px" marginBottom="12px" width="100%">
          {tAdmin.mailingAddress()}
        </TextV2>
        <Flex marginBottom="8px" width="100%">
          <Shipping color="mono12" />
          <Flex flexDirection="column" alignItems="flex-start" marginLeft="8px">
            <TextV2 color="mono12" typographyVariant="body2">{`${formik.values.address1}${formik.values.address2 ? ` ${formik.values.address2}` : ''}`}</TextV2>
            <TextV2 color="mono12" typographyVariant="body2">{`${formik.values.city},  ${formik.values.region} ${formik.values.postalCode}`}</TextV2>
            <TextV2 color="mono12" typographyVariant="body2">
              {formik.values.country}
            </TextV2>
          </Flex>
        </Flex>
        <ButtonV2 overrides={outlinedButtonOverrides} variant="outline" marginTop="32px" onClick={handleOnModifyDetailsClick} fullWidth>
          {tAdmin.modifyDetails()}
        </ButtonV2>
        <ButtonV2 marginTop="16px" onClick={handleOnLooksGoodClick} fullWidth disabled={hasMutationError}>
          {tAdmin.looksGood()}
        </ButtonV2>
        {hasMutationError && (
          <TextV2 typographyVariant="body3" color="red" marginTop="16px">
            {tAdmin.contactError()}
          </TextV2>
        )}
        <Footer />
      </Flex>
    </Wrapper>
  );
};
