import { withWindow } from '@shared/utils/withWindow';
import { useTranslation } from '@shared/core';
import { useContactCollectorTelemetry } from '@apps/contactCollector/ContactCollector.telemetry';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useNativeState } from '@shared/core/native';
import { dispatchContactCollectorEditGuestListToNativeApps } from '@shared/core/native/utils';

export const useRecentUpdatesController = (eventHandle: string) => {
  const { isNative } = useNativeState();
  const { value } = useFeatureValue('contactCollectionMobileCallbacksEnabled');
  const { manageButtonsClicked } = useContactCollectorTelemetry();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  const handleOnGoToGuestList = () => {
    if (isNative && value) {
      return dispatchContactCollectorEditGuestListToNativeApps();
    }
    manageButtonsClicked({ label: 'edit guest list' });
    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/guests`, '_blank');
    });
  };

  return {
    handleOnGoToGuestList,
    tAdmin
  };
};
