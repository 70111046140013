/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Heart Lock',
  definitions: {
    '64': {
      viewBox: '0 0 23 40',
      path: (
        <>
          <g clipPath="url(#clip0_32200_7680)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.5 3.18085C7.17573 3.18085 3.67021 6.68636 3.67021 11.0106V14.8032H1.71277C0.766832 14.8032 0 15.57 0 16.516V34.2553C0 35.2013 0.766833 35.9681 1.71277 35.9681H21.2872C22.2332 35.9681 23 35.2013 23 34.2553V16.516C23 15.57 22.2332 14.8032 21.2872 14.8032H19.3298V11.0106C19.3298 6.68636 15.8243 3.18085 11.5 3.18085ZM18.3511 14.8032V11.0106C18.3511 7.2269 15.2837 4.15957 11.5 4.15957C7.71626 4.15957 4.64894 7.2269 4.64894 11.0106V14.8032H6.72872V11.0106C6.72872 8.37553 8.8649 6.23936 11.5 6.23936C14.1351 6.23936 16.2713 8.37553 16.2713 11.0106V14.8032H18.3511ZM7.70745 11.0106V14.8032H15.2926V11.0106C15.2926 8.91607 13.5946 7.21808 11.5 7.21808C9.40543 7.21808 7.70745 8.91607 7.70745 11.0106ZM4.15957 15.7819L6.72872 15.7819H7.21809H15.7819H16.2713L18.8404 15.7819H19.3298H21.2872C21.6926 15.7819 22.0213 16.1106 22.0213 16.516V34.2553C22.0213 34.6607 21.6926 34.9894 21.2872 34.9894H1.71277C1.30737 34.9894 0.978724 34.6607 0.978724 34.2553V16.516C0.978724 16.1106 1.30737 15.7819 1.71277 15.7819H3.67021H4.15957ZM11.965 21.8222C13.1311 20.7759 14.9263 20.8135 16.0477 21.9348C17.1809 23.0681 17.2073 24.8894 16.1266 26.0544C16.1063 26.0762 16.0884 26.0996 16.0727 26.124L12.0191 30.1776C11.7324 30.4643 11.2676 30.4643 10.981 30.1776L6.92736 26.124C6.91171 26.0996 6.89373 26.0762 6.87343 26.0544C5.79281 24.8894 5.81913 23.0681 6.9524 21.9348C8.07377 20.8135 9.86891 20.7759 11.035 21.8222C11.1636 21.9376 11.3362 21.9844 11.5 21.957C11.6638 21.9844 11.8364 21.9376 11.965 21.8222ZM16.7397 21.2428C15.3079 19.8109 13.0506 19.7083 11.5 20.9348C9.9495 19.7083 7.69217 19.8109 6.26034 21.2428C4.77701 22.7261 4.72017 25.0955 6.08984 26.647C6.111 26.6835 6.13722 26.718 6.1685 26.7492L10.2889 30.8697C10.9578 31.5385 12.0423 31.5385 12.7111 30.8697L16.8316 26.7492C16.8628 26.718 16.8891 26.6835 16.9102 26.647C18.2799 25.0955 18.2231 22.7261 16.7397 21.2428Z"
              fill="#333333"
              stroke="#333333"
              strokeWidth="0.3"
            />
          </g>
          <defs>
            <clipPath id="clip0_32200_7680">
              <rect width="23" height="39.1489" fill="white" />
            </clipPath>
          </defs>
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
