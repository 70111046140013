/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Restart',
  definitions: {
    '16': {
      viewBox: '0 0 16 17',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0273 14.1725C11.2903 14.0206 11.3805 13.6842 11.2286 13.4212C11.0767 13.1581 10.7403 13.068 10.4773 13.2198C7.87057 14.7248 4.5374 13.8317 3.03243 11.225C1.52745 8.61831 2.42057 5.28515 5.02727 3.78017C7.63396 2.27519 10.9671 3.16831 12.4721 5.77501C12.4808 5.79002 12.49 5.80448 12.4999 5.81835L10.8493 5.50962C10.5508 5.45377 10.2634 5.65054 10.2076 5.94912C10.1517 6.2477 10.3485 6.53502 10.6471 6.59087L13.1045 7.05052C13.6745 7.15714 14.223 6.78149 14.3296 6.21148L14.7893 3.7541C14.8451 3.45552 14.6483 3.1682 14.3498 3.11235C14.0512 3.0565 13.7639 3.25327 13.708 3.55185L13.4023 5.18641C11.583 2.0834 7.59718 1.02626 4.47727 2.82754C1.34445 4.63627 0.271067 8.64219 2.0798 11.775C3.88853 14.9078 7.89445 15.9812 11.0273 14.1725ZM13.2587 5.95418C13.2563 5.95579 13.2539 5.95738 13.2515 5.95896L13.2576 5.96008L13.2587 5.95418Z"
            fill="#333333"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
