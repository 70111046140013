import { useFeatureValue } from '@shared/core/featureFlags';
import { useDisclosure } from '@withjoy/joykit';
import { useEffect, useMemo } from 'react';
import { getLocalStorage } from '@shared/core';
import { useNativeState } from '@shared/core/native';

const KEY = 'contactCollectorPrintCrossDialogSeen';
const TIMESTAMP_KEY = 'contactCollectorPrintCrossDialogSeenAt';

const localStorage = getLocalStorage();

const getLocalStorageKey = (key: string, eventId?: string) => (eventId ? `${eventId}-${key}` : '');

interface UsePrintCrossSellModalProps {
  eventId: string;
}

const usePrintCrossSellModal = ({ eventId }: UsePrintCrossSellModalProps) => {
  const { isNative } = useNativeState();
  const memoisedValues = useMemo(() => localStorage.getItem(getLocalStorageKey(KEY, eventId)), [eventId]);

  const { value: printWebsiteDesignerMatchingModalValue } = useFeatureValue('contactCollectionPrintCrossSellEnabled', {
    skip: memoisedValues === 'true' || isNative
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (printWebsiteDesignerMatchingModalValue === 'on') {
      onOpen();
      localStorage.setItem(getLocalStorageKey(KEY, eventId), 'true');
      localStorage.setItem(getLocalStorageKey(TIMESTAMP_KEY, eventId), new Date().toISOString());
    }
  }, [printWebsiteDesignerMatchingModalValue, onOpen, eventId]);

  const handleClose = () => {
    onClose();
  };

  return { isOpen, handleClose };
};

export default usePrintCrossSellModal;
