import React from 'react';
import { Box } from '@withjoy/joykit';
import { StyledParticleJoyLogo } from './Footer.styles';

export const Footer: React.FC = () => {
  return (
    <Box marginTop="40px" marginBottom="56px">
      <StyledParticleJoyLogo id="universalFooterLogo" href="https://www.withjoy.com" logoColor="#7D7D7D" />
    </Box>
  );
};
