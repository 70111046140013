import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { ApolloError } from '@apollo/client';

const category = 'guest.contactcollector';

const contactCollectorGuestTelemetry = createTelemetryObject({
  actions: {
    trackError: (action: 'Query' | 'Mutation' | 'Other', error: ApolloError | Error | unknown) => ({
      category,
      action,
      actionType: 'error',
      extraInfo: { error }
    }),
    landingPageViewed: ({ eventHandle }: { eventHandle: string }) => ({
      category,
      action: 'LandingPageViewed',
      extraInfo: {
        eventHandle
      }
    }),
    formSubmitted: ({
      url,
      eventHandle,
      guestFirstName,
      guestLastName,
      guestEmail
    }: {
      url: string;
      eventHandle: string;
      guestFirstName: string;
      guestLastName: string;
      guestEmail: string;
    }) => ({
      category,
      action: 'FormSubmitted',
      label: url,
      extraInfo: {
        eventHandle,
        guestFirstName,
        guestLastName,
        guestEmail
      }
    }),
    buttonInteracted: ({
      label,
      type
    }: {
      label: 'ProvideDetails' | 'VisitEventWebsite' | 'SaveDetails' | 'ConfirmAddress' | 'ConfirmAddressBack' | 'ModifyDetails' | 'LooksGood';
      type?: string;
    }) => ({
      category,
      action: 'ButtonInteracted',
      label,
      actionType: 'click',
      extraInfo: {
        type
      }
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(contactCollectorGuestTelemetry, { postgresEventId: '', firebaseEventId: '', joyEventType: '' });
const useContactCollectorGuestTelemetryTelemetry = useTelemetry;
export { TelemetryProvider, useContactCollectorGuestTelemetryTelemetry };
