import React from 'react';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import type { Property } from 'csstype';
import { FormikProps } from 'formik';
import { Confetti } from '@shared/components';
import { StyledText } from '@apps/thanksForRsvp/components/Congratulations/components/Header/Header.styles';
import { getImageSource } from '@apps/contactCollector/routes/Create/components/Preview/Preview';
import { VisualType } from '@apps/contactCollector/routes/Create/components/Editor/components/Switch/Switch';
import { Wrapper, Footer } from '../components';
import { ContactInputFields, Context } from '../../ContactCollectorGuest.controller';
import { useDoneController } from './Done.controller';

interface DoneProps
  extends Readonly<{
    formik: FormikProps<ContactInputFields>;
    context: Context;
  }> {}

export const Done: React.FC<DoneProps> = ({
  formik,
  context: { website, mediaType, mediaSource, fontFamily, fontTextTransform, eventDisplayName, eventDateDisplayEnabled, date, coupleNames, websiteLinkEnabled }
}) => {
  const { tAdmin, handleOnVisitEventWebsiteClick, isMobile } = useDoneController(formik, website);

  return (
    <>
      <Confetti />
      <Wrapper>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX="24px">
          <TextV2 typographyVariant="hed6" marginTop={['24px', null, '4px']} textAlign="center">
            {tAdmin.allSet({ name: formik.values.firstName })}
          </TextV2>
          <TextV2 typographyVariant="body2" marginY="16px" textAlign="center">
            {tAdmin.successMessage({ names: coupleNames })}
          </TextV2>
        </Flex>
        <img alt="source" src={getImageSource(mediaType as VisualType, mediaSource)} width="100%" style={{ borderRadius: isMobile ? 0 : '12px' }}></img>
        <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX="24px">
          <StyledText
            typographyVariant="hed2"
            marginTop="24px"
            fontSize={['30px', null, '40px']}
            fontFamily={fontFamily}
            textTransform={fontTextTransform as Property.TextTransform}
            $withMargin={false}
          >
            {eventDisplayName}
          </StyledText>
          {eventDateDisplayEnabled && date && (
            <TextV2 typographyVariant="body2" marginTop="8px">
              {date}
            </TextV2>
          )}
          {websiteLinkEnabled && (
            <ButtonV2 cursor="pointer" onClick={handleOnVisitEventWebsiteClick} variant="link" marginTop="32px" color="mono14" fontWeight={600} width={['100%', null, '327px']}>
              {tAdmin.visitEventWebsite()}
            </ButtonV2>
          )}
          <Footer />
        </Flex>
      </Wrapper>
    </>
  );
};
