import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Flex, ButtonV2, TextV2, IconV2, InputV2, Toggle } from '@withjoy/joykit';
import { Copy, Settings, OpenInNewWindow } from '@withjoy/joykit/icons';
import { FormikProps } from 'formik';
import { outlinedDarkButtonOverrides } from '@apps/postOffice/components/Shared.styles';
import { useYourMagicLinkController } from './YourMagicLink.controller';
import { ToggleTitle } from '@apps/contactCollector/routes/Create/components/Editor/Editor.styles';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';

interface YourMagicLinkProps
  extends Readonly<{
    magicLink: string;
    formik: FormikProps<ContactCollectorInputFields>;
  }> {}

export const YourMagicLink: React.FC<YourMagicLinkProps> = ({ magicLink, formik }) => {
  const {
    tAdmin,
    URL,
    FULL_URL,
    handleOnCopyClick,
    handleOnModifyDesignClick,
    handleOnPreviewClick,
    handleOnToggleChange,
    mutation: { isMutationLoading, mutationHasError }
  } = useYourMagicLinkController(magicLink, formik);

  const isContactCollectorActive = formik.values.active;

  return (
    <Flex flexDirection="column" justifyContent="center" width={['100%', null, '375px']} marginTop="48px">
      <Flex alignItems="center" justifyContent="space-between" paddingBottom={5} width="100%">
        <Flex flexDirection="column" alignItems="flex-start">
          <ToggleTitle>{tAdmin.enableContactCollector()}</ToggleTitle>
        </Flex>
        <Toggle hideLabel={true} label="enableContactCollector" checked={isContactCollectorActive} onChange={handleOnToggleChange} disabled={isMutationLoading} />
      </Flex>
      {!isContactCollectorActive && (
        <TextV2 typographyVariant="label1" color="mono12" marginBottom="8px">
          {tAdmin.disabledContactCollectorHelper()}
        </TextV2>
      )}
      {mutationHasError && (
        <TextV2 typographyVariant="label1" color="negative5">
          {tAdmin.enableContactCollectorError()}
        </TextV2>
      )}
      <TextV2 typographyVariant="label3" fontWeight={700} color="mono14" marginTop="24px" marginBottom="8px">
        {tAdmin.yourMagicLink()}
      </TextV2>
      <InputV2
        disabled
        value={URL}
        overrides={{
          Input: {
            props: {
              color: 'mono8'
            }
          }
        }}
      />
      <CopyToClipboard text={FULL_URL}>
        <ButtonV2 width="100%" marginTop="8px" marginBottom="24px" onClick={handleOnCopyClick} disabled={!isContactCollectorActive}>
          <IconV2 size="md" marginRight="8px">
            <Copy />
          </IconV2>{' '}
          {tAdmin.copyLink()}
        </ButtonV2>
      </CopyToClipboard>
      <ButtonV2 variant="outline" overrides={outlinedDarkButtonOverrides} onClick={handleOnModifyDesignClick} width="100%">
        <IconV2 size="md" marginRight="8px">
          <Settings />
        </IconV2>{' '}
        {tAdmin.modifyDesign()}
      </ButtonV2>
      <ButtonV2 variant="ghost" overrides={outlinedDarkButtonOverrides} onClick={handleOnPreviewClick} width="100%" marginTop="16px" disabled={!isContactCollectorActive}>
        <IconV2 size="md" marginRight="8px">
          <OpenInNewWindow />
        </IconV2>{' '}
        {tAdmin.previewAsGuest()}
      </ButtonV2>
    </Flex>
  );
};
