import { useEffect, useState } from 'react';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';

export const useScrollPosition = (onlyMobile = false) => {
  const isMobile = useIsMobileScreen();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (onlyMobile && !isMobile) return;
    const updatePosition = () => {
      setScrollPosition(window?.pageYOffset);
    };
    window?.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window?.removeEventListener('scroll', updatePosition);
  }, [isMobile, onlyMobile]);

  return scrollPosition;
};
