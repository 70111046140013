import React from 'react';
import { Box } from '@withjoy/joykit';
import { StyledTabNavWrapper, StyledTabItem } from './Switch.styles';
import { useTranslation } from '@shared/core';

export enum VisualType {
  ANIMATION = 'animation',
  IMAGE = 'image'
}

interface SwitchProps
  extends Readonly<{
    selectedVisualType: VisualType;
    setSelectedVisualType: (type: VisualType) => void;
  }> {}

export const Switch: React.FC<SwitchProps> = ({ selectedVisualType, setSelectedVisualType }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  const handleOnAnimationTabClick = () => {
    setSelectedVisualType(VisualType.ANIMATION);
  };

  const handleOnImageTabClick = () => {
    setSelectedVisualType(VisualType.IMAGE);
  };

  return (
    <Box display="flex" justifyContent="center">
      <StyledTabNavWrapper>
        <StyledTabItem $isActive={selectedVisualType === VisualType.ANIMATION} onClick={handleOnAnimationTabClick}>
          {tAdmin.selectAnimation()}
        </StyledTabItem>
        <StyledTabItem $isActive={selectedVisualType === VisualType.IMAGE} onClick={handleOnImageTabClick}>
          {tAdmin.selectImage()}
        </StyledTabItem>
      </StyledTabNavWrapper>
    </Box>
  );
};
