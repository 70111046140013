/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chat Double',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M2.663 7.542v.484 2.064l2.094-1.492c.011.422.074.83.184 1.22l-2.624 1.87a.48.48 0 0 1-.754-.389V8.027C.794 7.323.313 6.312.313 5.188A3.85 3.85 0 0 1 4.16 1.341h1.848c1.522 0 2.837.884 3.461 2.166-.398.02-.783.087-1.151.195-.489-.759-1.341-1.261-2.311-1.261H4.16a2.75 2.75 0 0 0-2.747 2.747c0 .802.343 1.523.893 2.027l.357.327zm10.675 3.765v-.484l.357-.327c.55-.504.893-1.225.893-2.027a2.75 2.75 0 0 0-2.747-2.747H9.992a2.75 2.75 0 0 0-2.747 2.747 2.75 2.75 0 0 0 2.747 2.747h.528.352l.287.204 2.179 1.553v-1.666zm-2.817 1.009l3.163 2.255a.48.48 0 0 0 .754-.389v-2.875c.768-.703 1.25-1.715 1.25-2.838a3.85 3.85 0 0 0-3.847-3.847H9.992a3.85 3.85 0 0 0-3.847 3.847 3.85 3.85 0 0 0 3.847 3.847h.528z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.377 11.437v.626 2.328l2.987-2.106c-.042.284-.063.576-.063.872a6.04 6.04 0 0 0 .032.619l-2.894 2.041a.8.8 0 0 1-1.261-.654v-3.1c-1.315-.917-2.176-2.441-2.176-4.166 0-2.802 2.272-5.074 5.074-5.074h2.437c2.56 0 4.676 1.895 5.024 4.359h-.26a6.02 6.02 0 0 0-.943.074c-.305-1.835-1.9-3.233-3.821-3.233H7.074c-2.139 0-3.874 1.734-3.874 3.874 0 1.316.655 2.479 1.662 3.181l.514.358zm13.246 5.886v-.626l.514-.358c1.007-.702 1.662-1.865 1.662-3.181 0-2.139-1.734-3.874-3.874-3.874h-2.437c-2.139 0-3.874 1.734-3.874 3.874s1.734 3.874 3.874 3.874h.316.398l.319.237 3.102 2.309v-2.254zm-1.697-9.239c2.802 0 5.074 2.271 5.074 5.074 0 1.725-.861 3.249-2.176 4.166v3.05a.8.8 0 0 1-1.278.642l-3.741-2.784h-.316c-2.802 0-5.074-2.272-5.074-5.074s2.272-5.074 5.074-5.074h2.437z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M6.816 15.64v.626 2.75l3.232-2.503.324-.251h.41.862a7.67 7.67 0 0 0-.188 1.2h-.674l-3.877 3.002a.8.8 0 0 1-1.29-.633v-3.566c-1.733-1.208-2.866-3.215-2.866-5.487 0-3.691 2.992-6.683 6.683-6.683h3.21c3.554 0 6.46 2.774 6.67 6.275h-.196a7.75 7.75 0 0 0-1.003.065c-.177-2.868-2.559-5.14-5.472-5.14h-3.21c-3.028 0-5.483 2.455-5.483 5.483 0 1.863.928 3.509 2.353 4.502l.514.358zm18.367 7.901v-.626l.514-.358c1.425-.993 2.353-2.639 2.353-4.502 0-3.028-2.455-5.483-5.483-5.483h-3.21c-3.028 0-5.483 2.455-5.483 5.483s2.455 5.483 5.483 5.483h1.865.41l.324.251 3.226 2.498v-2.746zm-2.616-12.17c3.691 0 6.683 2.992 6.683 6.683 0 2.272-1.134 4.279-2.867 5.487v3.561a.8.8 0 0 1-1.29.633l-3.871-2.998h-1.865c-3.691 0-6.683-2.992-6.683-6.683s2.992-6.683 6.683-6.683h3.21z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M9.011 24.029v.652 5l5.109-4.113.343-.276h.441 2.279a11.94 11.94 0 0 0-.162 1.25h-2.118l-5.109 4.113c-.818.658-2.034.076-2.034-.974v-5c-2.695-1.878-4.459-5.001-4.459-8.535A10.4 10.4 0 0 1 13.697 5.751h4.993a10.4 10.4 0 0 1 10.376 9.761h-.13l-1.12.052a9.15 9.15 0 0 0-9.127-8.563h-4.993a9.15 9.15 0 0 0-9.145 9.145c0 3.108 1.549 5.855 3.924 7.509l.535.373zm29.978 11.968v-.653l.535-.373c2.374-1.655 3.924-4.401 3.924-7.509a9.15 9.15 0 0 0-9.145-9.145H29.31a9.15 9.15 0 0 0-9.145 9.145 9.15 9.15 0 0 0 9.145 9.145h3.786.441l.343.276 5.109 4.113v-5zm-4.686-18.93a10.4 10.4 0 0 1 10.395 10.395c0 3.534-1.764 6.656-4.459 8.535v5c0 1.05-1.216 1.632-2.034.974l-5.109-4.113H29.31a10.4 10.4 0 0 1-10.395-10.395A10.4 10.4 0 0 1 29.31 17.067h4.993z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M10.47 31.982v.835 7.531l7.601-6.119.439-.354h.564 3.079l-.198 1.6h-2.881l-7.764 6.25c-.981.79-2.441.092-2.441-1.168v-7.74C5.02 30.134 2.5 25.674 2.5 20.625c0-8.202 6.649-14.851 14.851-14.851h7.133c7.897 0 14.355 6.164 14.823 13.944h-.329c-.428 0-.851.016-1.271.047-.443-6.917-6.194-12.39-13.223-12.39h-7.133C10.033 7.374 4.1 13.307 4.1 20.625c0 4.503 2.245 8.483 5.685 10.88l.685.477zm43.06 17.001v-.835l.685-.477c3.439-2.397 5.685-6.376 5.685-10.88 0-7.318-5.932-13.251-13.251-13.251h-7.133c-7.318 0-13.251 5.933-13.251 13.251s5.933 13.25 13.251 13.25h5.409.564l.439.354 7.601 6.119v-7.531zM46.649 21.94c8.202 0 14.851 6.649 14.851 14.851 0 5.049-2.52 9.509-6.37 12.192v7.74c0 1.26-1.459 1.958-2.441 1.168l-7.764-6.25h-5.409c-8.202 0-14.851-6.649-14.851-14.851S31.315 21.94 39.516 21.94h7.133z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
