import React from 'react';
import { FormikProps } from 'formik';
import { Flex, TextV2 } from '@withjoy/joykit';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { Preview } from '../Create/components';
import { YourMagicLink, RecentUpdates } from './components';
import { useTranslation } from '@shared/core';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useQueryParamHelper } from '@shared/core/queryString';
import { ErrorSplash } from '@shared/components';
import { useFeatureValue } from '@shared/core/featureFlags';
import PrintCrossSell from '@apps/contactCollector/components/PrintCrossSell';

interface ManageProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    eventHandle: string;
  }> {}

export const Manage: React.FC<ManageProps> = ({ formik, eventHandle }) => {
  const { value: contactCollectionPrintCrossSellEnabled } = useFeatureValue('contactCollectionPrintCrossSellEnabled');
  const printCrossSellEnabled = contactCollectionPrintCrossSellEnabled === 'on';
  const { getValueString } = useQueryParamHelper();
  const magicLink = getValueString('magicLink') || formik.values.magicLink;
  const isMobile = useIsMobileScreen();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  if (!magicLink) return <ErrorSplash customError={{ title: tAdmin.errorPageTitle(), subtitle: tAdmin.errorPageSubTitle() }} />;

  return (
    <>
      <Flex width="50%" display={['none', null, 'flex']} flexDirection="column" alignItems="center" paddingTop="32px">
        <Flex width="375px" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <TextV2 typographyVariant="display1" fontWeight={700} marginBottom="8px">
            {tAdmin.previewTitle()}
          </TextV2>
          <TextV2 typographyVariant="body2" fontWeight={400} color="mono12">
            {tAdmin.previewSubTitle()}
          </TextV2>
        </Flex>
        <YourMagicLink magicLink={magicLink} formik={formik} />
        <RecentUpdates contacts={formik.values.contacts} eventHandle={eventHandle} />
      </Flex>
      <Flex width={['100%', null, '50%']} paddingTop="32px" flexDirection="column" alignItems="center" paddingX={['16px', null, 0]} backgroundColor={['white', null, 'mono1']}>
        <Flex display={['flex', null, 'none']} flexDirection="column">
          <TextV2 typographyVariant="hed6" fontWeight={700} paddingBottom="8px">
            {tAdmin.previewTitle()}
          </TextV2>
          <TextV2 typographyVariant="body1" fontWeight={400} paddingBottom="16px">
            {tAdmin.previewSubTitle()}
          </TextV2>
        </Flex>
        <Preview formik={formik} hideInstructions={isMobile} />
        {printCrossSellEnabled && (
          <Flex marginTop={8} display={['none', null, 'flex']} maxWidth="500px" marginBottom={7}>
            <PrintCrossSell />
          </Flex>
        )}
        <Flex display={['flex', null, 'none']} flexDirection="column" width="100%">
          <YourMagicLink magicLink={magicLink} formik={formik} />
          {printCrossSellEnabled && (
            <Flex marginTop={9} paddingX={6} display={['flex', null, 'none']}>
              <PrintCrossSell />
            </Flex>
          )}
          <RecentUpdates contacts={formik.values.contacts} eventHandle={eventHandle} />
        </Flex>
      </Flex>
    </>
  );
};
