import React from 'react';
import { FormikProps } from 'formik';
import { Switch, AnimationPicker, Date } from './components';
import { useEditorController } from './Editor.controller';
import { FormControl, TextV2, Flex, Toggle, ButtonV2 } from '@withjoy/joykit';
import { Label, InputLengthLabel, ToggleTitle, ToggleWrapper, LinkContainer, Link, RestartIcon } from './Editor.styles';
import { StyledTextArea } from '@shared/components/EmailsAndEcards/components/shared';
import { EmailsAndEcardsFormControlOverrides } from '@shared/components/EmailsAndEcards/Emails.utils';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import PhotoSelector from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector';
import { VisualType } from './components/Switch/Switch';
import { filterTidbitsPage } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector/PhotoSelector.controller';
import { ContactCollectionEventFragment } from '@graphql/generated';

interface EditorProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    eventHandle: string;
    eventByName: Maybe<ContactCollectionEventFragment>;
    setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
    isMutationLoading: boolean;
    hasMutationError: boolean;
  }> {}

export const Editor: React.FC<EditorProps> = ({ formik, eventHandle, eventByName, setOpenDialog, isMutationLoading, hasMutationError }) => {
  const {
    selectedVisualType,
    setSelectedVisualType,
    handleOnGoToSettingsClick,
    handleOnGoToSecuritySettingsClick,
    handleOnCreateMagicLinkClick,
    handleOnRestartClick,
    mediaSource,
    message,
    websiteLinkEnabled,
    eventDateDisplayEnabled,
    pages,
    privateEventDate,
    names,
    date,
    tAdmin,
    placeHolder,
    isUpdating
  } = useEditorController(eventHandle, eventByName, formik, setOpenDialog);

  return (
    <Flex width="100%" maxWidth="375px" flexDirection="column" alignItems={['center', null, 'flex-start']} justifyContent="center">
      <TextV2 typographyVariant="hed2" fontWeight={600} marginTop={['16px', null, '32px']} marginBottom="16px">
        {tAdmin.visual()}
      </TextV2>
      <Switch selectedVisualType={selectedVisualType} setSelectedVisualType={setSelectedVisualType} />
      {selectedVisualType === VisualType.ANIMATION && (
        <AnimationPicker
          handleSelect={(mediaSource: string) => {
            formik.setFieldValue('mediaType', selectedVisualType);
            formik.setFieldValue('mediaSource', mediaSource);
          }}
          imageSource={mediaSource}
        />
      )}
      {selectedVisualType === VisualType.IMAGE && (
        <FormControl label={<Label>{tAdmin.photo()}</Label>} overrides={EmailsAndEcardsFormControlOverrides} width="100%" marginTop="16px">
          <>
            <PhotoSelector
              pages={filterTidbitsPage(pages)}
              handlePhotoSelect={({ previewUrl }) => {
                formik.setFieldValue('mediaType', selectedVisualType);
                formik.setFieldValue('mediaSource', previewUrl);
              }}
              photo={{ url: mediaSource || '' }}
              allowNoPhoto={false}
            />
            <TextV2 marginTop={2} color="inputBorderError" height={2} typographyVariant="label1">
              {formik.getFieldMeta('mediaSource').error}
            </TextV2>
          </>
        </FormControl>
      )}
      <TextV2 typographyVariant="hed2" fontWeight={600} marginTop="32px" marginBottom="16px">
        {tAdmin.note()}
      </TextV2>
      <FormControl
        label={
          <Flex justifyContent="space-between">
            <Label>{tAdmin.message()}</Label>
            <RestartIcon onClick={handleOnRestartClick} />
          </Flex>
        }
        error={formik.errors.message}
        overrides={EmailsAndEcardsFormControlOverrides}
        width="100%"
      >
        <>
          <StyledTextArea
            {...formik.getFieldProps('message')}
            value={message}
            placeholder={placeHolder || tAdmin.messagePlaceholder({ names: names })}
            resize={false}
            minRows={4}
            maxRows={8}
            tabIndex={0}
            maxLength={120}
            minimal={true}
          />
          <InputLengthLabel>{message?.length} / 120</InputLengthLabel>
          <TextV2 marginTop={2} color="inputBorderError" height={2} typographyVariant="label1">
            {formik.getFieldMeta('message').error}
          </TextV2>
        </>
      </FormControl>
      <ToggleWrapper flexDirection="column" alignItems="center" justifyContent="center" width="100%" marginY="32px">
        <Flex alignItems="center" justifyContent="space-between" paddingX={4} paddingY={5} width="100%">
          <Flex flexDirection="column" alignItems="flex-start">
            <ToggleTitle>{tAdmin.linkWebsite()}</ToggleTitle>
          </Flex>
          <Toggle
            hideLabel={true}
            label="displayEventLink"
            checked={websiteLinkEnabled}
            name={formik.getFieldProps('websiteLinkEnabled').name}
            onChange={() => formik.setFieldValue('websiteLinkEnabled', !websiteLinkEnabled)}
          />
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" paddingX={4} paddingY={5} width="100%" borderTop="1px solid #EBEBEB">
          <Flex flexDirection="column" alignItems="flex-start">
            <ToggleTitle>{tAdmin.displayEventDate()}</ToggleTitle>
          </Flex>
          <Toggle
            hideLabel={true}
            label="displayEventDate"
            checked={eventDateDisplayEnabled}
            name={formik.getFieldProps('eventDateDisplayEnabled').name}
            onChange={() => formik.setFieldValue('eventDateDisplayEnabled', !eventDateDisplayEnabled)}
            disabled={privateEventDate}
          />
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start" paddingLeft={4} paddingBottom={5} width="100%">
          {date && <Date date={date} />}
          <LinkContainer>
            <Link onClick={handleOnGoToSettingsClick}>{tAdmin.editDate()}</Link>
          </LinkContainer>
          {privateEventDate && (
            <LinkContainer $extraMargin>
              {tAdmin.privateDate()} <Link onClick={handleOnGoToSecuritySettingsClick}>{tAdmin.settings()}</Link>
              {tAdmin.refresh()}
            </LinkContainer>
          )}
        </Flex>
      </ToggleWrapper>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" width="100%">
        <ButtonV2
          variant="solid"
          intent="primary"
          onClick={handleOnCreateMagicLinkClick}
          disabled={!formik.isValid || isMutationLoading}
          loading={isMutationLoading}
          width="100%"
          marginBottom="16px"
        >
          {isUpdating ? tAdmin.updateMagicLink() : tAdmin.createMagicLink()}
        </ButtonV2>
        {hasMutationError && (
          <TextV2 typographyVariant="label1" color="negative5" marginBottom="16px">
            {tAdmin.magicLinkError()}
          </TextV2>
        )}
      </Flex>
    </Flex>
  );
};
