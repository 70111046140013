import React, { useState } from 'react';
import ConfettiComponent from 'react-confetti';
import { useWindowResizeObserver, useWindowResizeSubscriber } from '@shared/utils/hooks/useWindowResize';

interface ConfettiProps {
  numberOfPieces?: number;
}

export const Confetti: React.FC<ConfettiProps> = ({ numberOfPieces = 500 }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useWindowResizeObserver();

  useWindowResizeSubscriber(size => {
    setScreenWidth(size.width || window.innerWidth);
    setScreenHeight(size.height || window.innerHeight);
  });

  return <ConfettiComponent width={screenWidth} height={screenHeight} recycle={false} numberOfPieces={numberOfPieces} />;
};
