import { ApolloError } from '@apollo/client';
import { GetEventForAdminMenuV2Query, useGetEventForAdminMenuV2Query } from '@graphql/generated';
import { ApolloQueryResult } from '@apollo/client/core/types';

export interface DashboardData {
  loading: boolean;
  data?: GetEventForAdminMenuV2Query;
  error?: ApolloError;
  refetch: () => Promise<ApolloQueryResult<GetEventForAdminMenuV2Query>>;
}

export function useDashboardData(eventHandle: string): DashboardData {
  return useGetEventForAdminMenuV2Query({ batchMode: 'off', variables: { eventHandle } });
}
