import { styled, TextV2 } from '@withjoy/joykit';

export const StyledText = styled(TextV2)<{ $withMargin?: boolean }>`
  line-height: 1;
  word-wrap: normal;
  width: 100%;
  white-space: inherit;
  text-align: center;
  font-weight: 400;

  ${({ $withMargin = true }) => $withMargin && 'margin: 64px 0 32px 0;'};
`;
