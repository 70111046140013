import React from 'react';
import { FormikProps } from 'formik';
import { DialogV2, TextV2, IconV2 } from '@withjoy/joykit';
import { DesignEmail } from '@withjoy/joykit/icons';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { Editor } from '../index';
import { useTranslation } from '@shared/core';
import { ContactCollectionEventFragment } from '@graphql/generated';

interface EditDesignDialogProps
  extends Readonly<{
    openDialog: boolean;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    formik: FormikProps<ContactCollectorInputFields>;
    eventHandle: string;
    eventByName: Maybe<ContactCollectionEventFragment>;
    isMutationLoading: boolean;
    hasMutationError: boolean;
  }> {}

export const EditDesignDialog: React.FC<EditDesignDialogProps> = ({ openDialog, setOpenDialog, formik, eventHandle, eventByName, isMutationLoading, hasMutationError }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');
  const isMobile = useIsMobileScreen();

  if (!isMobile) return null;

  const handleOnClose = () => {
    setOpenDialog(false);
    formik.handleReset();
  };

  return (
    <DialogV2
      id="edit-design"
      isOpen={openDialog}
      onClose={handleOnClose}
      size="sm"
      contentTransition={isMobile ? { out: { opacity: 0, scale: 1 }, in: { opacity: 1, scale: 1 } } : undefined}
    >
      <DialogV2.Header justifyContent="space-between" alignItems="center" paddingTop="24px">
        <TextV2 typographyVariant="hed6" style={{ fontWeight: 700 }}>
          <IconV2 size="md" marginRight="12px">
            <DesignEmail />
          </IconV2>{' '}
          {tAdmin.editDesign()}
        </TextV2>
        <DialogV2.CloseButton zIndex="modal" color="mono14" />
      </DialogV2.Header>
      <DialogV2.Body paddingX="16px">
        <Editor
          formik={formik}
          eventHandle={eventHandle}
          eventByName={eventByName}
          setOpenDialog={setOpenDialog}
          isMutationLoading={isMutationLoading}
          hasMutationError={hasMutationError}
        />
      </DialogV2.Body>
    </DialogV2>
  );
};
