import React from 'react';
import { Redirect, Route, Switch } from '@react-router';
import { Flex, IconV2, LinkV2, TooltipV2 } from '@withjoy/joykit';
import { Hamburger, Help } from '@withjoy/joykit/icons';
import { FormikProps } from 'formik';
import { RoutePath, RoutesProvider, useRouterHelper } from '@shared/core';
import { useContactCollectorController, ContactCollectorInputFields } from './ContactCollector.controller';
import { Create, Manage } from './routes';
import { TelemetryProvider } from './ContactCollector.telemetry';
import { ErrorSplash } from '@shared/components';
import { ContactCollectionEventFragment } from '@graphql/generated';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import PrintCrossSellModal from './components/PrintCrossSellModal';
import { UniversalBurgerMenu } from '@apps/admin/components/UniversalBurgerMenu/UniversalBurgerMenu';
import { HeaderBar, HeaderBarText } from '@shared/components/EmailsAndEcards/components/Header/Header.styles';

const HELP_URL = 'https://help.withjoy.com/knowledge-base/contact-collector-a-step-by-step-guide';

interface ContactCollectorProps
  extends Readonly<{
    eventId: string;
    eventHandle: string;
  }> {}

export const contactCollectorRoutePaths = {
  create: {
    path: 'create',
    goToPath: () => 'create'
  },
  manage: {
    path: 'manage',
    goToPath: () => 'manage'
  }
};

const RoutesContactCollector: React.FC<{
  formik: FormikProps<ContactCollectorInputFields>;
  eventHandle: string;
  eventByName: Maybe<ContactCollectionEventFragment>;
  isFirstRun: boolean;
  isMutationLoading: boolean;
  hasMutationError: boolean;
}> = ({ formik, eventHandle, eventByName, isFirstRun, isMutationLoading, hasMutationError }) => {
  const routeHelpers = useRouterHelper();
  const magicLink = formik.values.magicLink;

  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(contactCollectorRoutePaths.create.path),
      component: args =>
        !isFirstRun && magicLink && args.location.search !== '?modify=true' ? (
          <Redirect to={routes[1].path} />
        ) : (
          <Create
            formik={formik}
            eventHandle={eventHandle}
            eventByName={eventByName}
            isFirstRun={isFirstRun}
            isMutationLoading={isMutationLoading}
            hasMutationError={hasMutationError}
          />
        )
    },
    {
      path: routeHelpers.buildPath(contactCollectorRoutePaths.manage.path),
      component: () => <Manage formik={formik} eventHandle={eventHandle} />
    }
  ];

  return (
    <Switch>
      {routes.map(route => (
        <Route key={route.path} path={route.path} render={route.component} exact />
      ))}
      <Redirect to={routes[0].path} />
    </Switch>
  );
};

const ContactCollector: React.FC<ContactCollectorProps> = ({ eventId, eventHandle }) => {
  const { formik, eventByName, isFirstRun, isQueryLoading, showErrorPage, tAdmin, isMutationLoading, hasMutationError } = useContactCollectorController(eventId, eventHandle);

  if (isQueryLoading) return <JoyLogoLoader loaderKey="contact-collector" />;
  if (showErrorPage) return <ErrorSplash customError={{ title: tAdmin.errorPageTitle(), subtitle: tAdmin.errorPageSubTitle() }} />;

  return (
    <TelemetryProvider>
      <RoutesProvider appUrl="contact" handle={eventHandle}>
        <HeaderBar alignItems="center">
          <UniversalBurgerMenu>
            <Hamburger size="lg" />
          </UniversalBurgerMenu>
          <HeaderBarText>Contact Collector</HeaderBarText>
        </HeaderBar>
        <Flex position="absolute" top="22px" right="30px" alignItems="center">
          <TooltipV2 content={tAdmin.help()} placement="bottom" onMouseEnterDelay={500}>
            <LinkV2 href={HELP_URL} isExternal>
              <IconV2 size="md">
                <Help />
              </IconV2>
            </LinkV2>
          </TooltipV2>
        </Flex>
        <Flex paddingTop={['64px', null, '80px']} height="100%" width="100%">
          <Flex borderTop={['none', null, '1px solid #EBEBEB']} height="100%" width="100%">
            <RoutesContactCollector
              formik={formik}
              eventHandle={eventHandle}
              eventByName={eventByName}
              isFirstRun={isFirstRun}
              isMutationLoading={isMutationLoading}
              hasMutationError={hasMutationError}
            />
          </Flex>
        </Flex>
        <PrintCrossSellModal eventId={eventId} eventHandle={eventHandle} />
      </RoutesProvider>
    </TelemetryProvider>
  );
};

export default ContactCollector;
