import React from 'react';
import { format, parseISO } from 'date-fns';
import { Flex, TextV2, IconV2, ButtonV2, Box } from '@withjoy/joykit';
import { People } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '@apps/postOffice/components/Shared.styles';
import { useRecentUpdatesController } from './RecentUpdates.controller';
import { Item } from './components/Item/Item';
import { Contact } from '@apps/contactCollector/ContactCollector.controller';

interface RecentUpdatesProps
  extends Readonly<{
    contacts?: Maybe<Contact>[];
    eventHandle: string;
  }> {}

export const RecentUpdates: React.FC<RecentUpdatesProps> = ({ contacts, eventHandle }) => {
  const { handleOnGoToGuestList, tAdmin } = useRecentUpdatesController(eventHandle);

  const renderContactsList = () =>
    !contacts?.length ? (
      <Item title={tAdmin.startSharing()} subTitle={tAdmin.recentUpdatesPlaceholder()} />
    ) : (
      <Box>
        {contacts.map(contact => (
          <Item
            key={`${contact?.firstName}-${contact?.lastName}-${contact?.createdAt}`}
            title={contact?.firstName && contact?.lastName ? `${contact.firstName} ${contact.lastName}` : 'Contact Name'}
            subTitle={contact?.createdAt ? format(parseISO(contact.createdAt), 'PPPPpp') : 'Creation Date'}
          />
        ))}
      </Box>
    );

  return (
    <Flex flexDirection="column" justifyContent="center" width={['100%', null, '375px']} marginTop="48px" marginBottom={['42px', null, 0]} paddingBottom={[0, null, '20px']}>
      <Flex alignItems="center" justifyContent="space-between">
        <TextV2 typographyVariant="hed4" fontWeight={700} color="mono14">
          {tAdmin.recentUpdates()}
        </TextV2>
        <ButtonV2 variant="ghost" overrides={outlinedDarkButtonOverrides} onClick={handleOnGoToGuestList} paddingX={0}>
          <IconV2 size="md" marginRight="8px">
            <People />
          </IconV2>{' '}
          {tAdmin.editGuestList()}
        </ButtonV2>
      </Flex>
      {renderContactsList()}
    </Flex>
  );
};
