import { styled } from '@withjoy/joykit';
import { animationTransition } from '@shared/utils/animationTransition';

export const Thumbnail = styled.div<{ image: string; isActive: boolean; width: number; height: number }>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  border: ${({ theme, isActive }) => (isActive ? `2px solid ${theme.colors.mono14}` : '1px solid transparent')};
  transition: ${animationTransition('border')};
  cursor: pointer;
  border-radius: 4px;
  margin: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[1]};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.mono14};
  }
`;
