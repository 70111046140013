import React from 'react';
import { TextV2, Divider } from '@withjoy/joykit';

interface ItemProps
  extends Readonly<{
    title: string;
    subTitle: string;
  }> {}

export const Item: React.FC<ItemProps> = ({ title, subTitle }) => {
  return (
    <>
      <Divider marginY="16px" />
      <TextV2 typographyVariant="body3" fontWeight={400} color="black" marginBottom="8px">
        {title}
      </TextV2>
      <TextV2 typographyVariant="body3" fontWeight={400} color="mono10" fontSize="15px">
        {subTitle}
      </TextV2>
    </>
  );
};
