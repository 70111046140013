import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from '@react-router';
import { Helmet } from 'react-helmet-async';
import { FormikProps } from 'formik';
import { ErrorSplash } from '@shared/components';
import { useContactCollectorGuestController, ContactInputFields, Context } from './ContactCollectorGuest.controller';
import { RoutePath, RoutesProvider, useRouterHelper } from '@shared/core';
import { TelemetryProvider } from './ContactCollectorGuest.telemetry';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { Landing, Form, Address, Confirmation, Done } from './routes';
import { GetContactCollectionByMagicLinkQuery } from '@graphql/generated';
import { NoSsr } from '@shared/components/NoSsr';
import { createGlobalStyle } from '@withjoy/joykit';
import { withWindow } from '@shared/utils/withWindow';
// Load Fonts
import '@assets/charm.css';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

interface ContactCollectorGuestProps
  extends Readonly<{
    magicLinkId: string;
  }> {}

export const contactCollectorGuestRoutePaths = {
  landing: {
    path: 'landing',
    goToPath: () => 'landing'
  },
  form: {
    path: 'form',
    goToPath: () => 'form'
  },
  address: {
    path: 'address',
    goToPath: () => 'address'
  },
  confirmation: {
    path: 'confirmation',
    goToPath: () => 'confirmation'
  },
  done: {
    path: 'done',
    goToPath: () => 'done'
  }
};

const RoutesContactCollectorGuest: React.FC<{
  data?: GetContactCollectionByMagicLinkQuery;
  formik: FormikProps<ContactInputFields>;
  context: Context;
  setContext: React.Dispatch<React.SetStateAction<Context>>;
  isMutationLoading: boolean;
  hasMutationError: boolean;
}> = ({ data, formik, context, setContext, isMutationLoading, hasMutationError }) => {
  const routeHelpers = useRouterHelper();

  const routes: Array<RoutePath<{}>> = [
    {
      path: routeHelpers.buildPath(contactCollectorGuestRoutePaths.landing.path),
      component: () => <Landing data={data} setContext={setContext} />
    },
    {
      path: routeHelpers.buildPath(contactCollectorGuestRoutePaths.form.path),
      component: () => <Form formik={formik} context={context} />
    },
    {
      path: routeHelpers.buildPath(contactCollectorGuestRoutePaths.address.path),
      component: () => <Address formik={formik} />
    },
    {
      path: routeHelpers.buildPath(contactCollectorGuestRoutePaths.confirmation.path),
      component: () => <Confirmation formik={formik} context={context} isMutationLoading={isMutationLoading} hasMutationError={hasMutationError} />
    },
    {
      path: routeHelpers.buildPath(contactCollectorGuestRoutePaths.done.path),
      component: () => <Done formik={formik} context={context} />
    }
  ];

  return (
    <>
      <GlobalStyle />
      <Switch>
        {routes.map(route => (
          <Route key={route.path} path={route.path} render={route.component} exact />
        ))}
        <Redirect to={routes[0].path} />
      </Switch>
    </>
  );
};

const ContactCollectorGuest: React.FC<ContactCollectorGuestProps> = ({ magicLinkId }) => {
  const {
    tAdmin,
    showErrorPage,
    isContactCollectionInactive,
    eventHandle,
    isLoading,
    data,
    noData,
    ogImage,
    ogTitle,
    ogUrl,
    formik,
    context,
    setContext,
    isMutationLoading,
    hasMutationError,
    telemetryData
  } = useContactCollectorGuestController(magicLinkId);

  const Head = useMemo(() => {
    return (
      <Helmet title={tAdmin.title()}>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:site_name" content="Joy" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="website" />
      </Helmet>
    );
  }, [ogImage, ogTitle, ogUrl, tAdmin]);

  if (isLoading)
    return (
      <>
        {Head}
        <JoyLogoLoader loaderKey="contact-collector-guest" />
      </>
    );
  if (showErrorPage) return <ErrorSplash customError={{ title: tAdmin.errorPageTitle(), subtitle: tAdmin.errorPageSubTitle() }} />;
  if (noData) return <PageNotFound />;
  if (isContactCollectionInactive) {
    if (eventHandle) {
      withWindow(() => {
        window.location.href = window.location.origin + `/${eventHandle}`;
      });
      return null;
    } else {
      return <ErrorSplash customError={{ title: tAdmin.errorPageTitle(), subtitle: tAdmin.errorPageSubTitle() }} />;
    }
  }

  return (
    <NoSsr>
      <TelemetryProvider context={telemetryData}>
        <RoutesProvider appUrl="contact/:magicLinkId">
          {Head}
          <RoutesContactCollectorGuest
            data={data}
            formik={formik}
            context={context}
            setContext={setContext}
            isMutationLoading={isMutationLoading}
            hasMutationError={hasMutationError}
          />
        </RoutesProvider>
      </TelemetryProvider>
    </NoSsr>
  );
};

export default ContactCollectorGuest;
