import { styled } from '@withjoy/joykit';
import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';

export const StyledParticleJoyLogo = styled(ParticleJoyLogo)`
  justify-content: center;
  svg,
  canvas {
    width: 38px;
    height: initial;
  }
`;
