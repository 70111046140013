import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { VisualType } from './routes/Create/components/Editor/components/Switch/Switch';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useGetContactCollectionByEventIdAndNamesByEventHandleQuery, useCreateContactCollectionMutation, useUpdateContactCollectionMutation } from '@graphql/generated';
import { contactCollectorRoutePaths } from '@apps/contactCollector';
import { useContactCollectorTelemetry } from '@apps/contactCollector/ContactCollector.telemetry';

export type Contact = {
  firstName: string;
  lastName: string;
  createdAt: string;
};

export interface ContactCollectorInputFields
  extends Readonly<{
    id?: string;
    mediaType: VisualType;
    mediaSource: string;
    message: string;
    websiteLinkEnabled: boolean;
    eventDateDisplayEnabled: boolean;
    contacts?: Maybe<Contact>[];
    magicLink?: string;
    active: boolean;
  }> {}

export const useContactCollectorController = (eventId: string, eventHandle: string) => {
  const { trackError } = useContactCollectorTelemetry();
  const routeHelpers = useRouterHelper();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  const initialState: ContactCollectorInputFields = {
    mediaType: VisualType.ANIMATION,
    mediaSource: 'doodles',
    message: tAdmin.messagePlaceholder({ names: 'The Couple' }),
    websiteLinkEnabled: false,
    eventDateDisplayEnabled: false,
    contacts: [],
    active: true
  };

  const { value: contactCollectionEnabled } = useFeatureValue('contactCollectionEnabled');

  const [contactCollectorData, setContactCollectorData] = useState<ContactCollectorInputFields>(initialState);

  const { data, loading, error } = useGetContactCollectionByEventIdAndNamesByEventHandleQuery({
    batchMode: 'fast',
    variables: {
      eventId,
      eventHandle
    },
    onError: error => trackError('Query', error)
  });

  const eventByName = data?.eventByName;
  const info = eventByName?.info;
  const names = (info?.ownerFirstName && info?.fianceeFirstName ? `${info?.ownerFirstName} & ${info?.fianceeFirstName}` : info?.ownerFirstName) || tAdmin.theCouple();
  const isFirstRun = data?.getContactCollectionByEventId === null;

  const [createContactCollection, { loading: createContactCollectionLoading, error: createContactCollectionError }] = useCreateContactCollectionMutation({
    refetchQueries: ['GetContactCollectionByEventIdAndNamesByEventHandle']
  });

  const [updateContactCollectionMutation, { loading: updateContactCollectionLoading, error: updateContactCollectionError }] = useUpdateContactCollectionMutation({
    refetchQueries: ['GetContactCollectionByEventIdAndNamesByEventHandle']
  });

  const formik = useFormik<ContactCollectorInputFields>({
    initialValues: contactCollectorData,
    validationSchema: Yup.object<ContactCollectorInputFields>({
      mediaType: Yup.mixed<VisualType>().required(),
      mediaSource: Yup.string().required(),
      message: Yup.string().required(),
      websiteLinkEnabled: Yup.boolean(),
      eventDateDisplayEnabled: Yup.boolean(),
      id: Yup.string(),
      contacts: Yup.array<Contact>(),
      magicLink: Yup.string(),
      active: Yup.boolean()
    }),
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const data = {
          eventDateDisplayEnabled: values.eventDateDisplayEnabled,
          eventId,
          mediaSource: values.mediaSource,
          mediaType: values.mediaType,
          message: values.message,
          websiteLinkEnabled: values.websiteLinkEnabled
        };

        let magicLink;

        if (isFirstRun && !values.id) {
          const response = await createContactCollection({
            variables: { data }
          });
          formik.setFieldValue('id', response.data?.createContactCollection.id);
          magicLink = response.data?.createContactCollection.magicLink;
        } else {
          const response = await updateContactCollectionMutation({
            variables: { id: values.id!, data }
          });
          magicLink = response.data?.updateContactCollection.magicLink;
        }

        return routeHelpers.goToExactRoute(contactCollectorRoutePaths.manage.path, `?magicLink=${magicLink}`);
      } catch (error) {
        trackError('Mutation', error);
      }
    }
  });

  useEffect(() => {
    if (data?.getContactCollectionByEventId) {
      setContactCollectorData({
        id: data.getContactCollectionByEventId.id,
        mediaType: data.getContactCollectionByEventId.mediaType as VisualType,
        mediaSource: data.getContactCollectionByEventId.mediaSource,
        message: data.getContactCollectionByEventId.message,
        websiteLinkEnabled: data.getContactCollectionByEventId.websiteLinkEnabled,
        eventDateDisplayEnabled:
          data.eventByName?.settings?.requirePasswordForEventDate === undefined
            ? data.getContactCollectionByEventId.eventDateDisplayEnabled
            : !data.eventByName.settings.requirePasswordForEventDate,
        contacts: data.getContactCollectionByEventId.contacts ?? [],
        magicLink: data.getContactCollectionByEventId.magicLink,
        active: !!data.getContactCollectionByEventId.active
      });
    } else if (data?.eventByName) {
      setContactCollectorData(prevState => ({
        ...prevState,
        eventDateDisplayEnabled: !data.eventByName?.settings?.requirePasswordForEventDate,
        message: tAdmin.messagePlaceholder({ names: names })
      }));
    }
  }, [data, names, tAdmin]);

  return {
    formik,
    eventByName,
    isFirstRun,
    isQueryLoading: loading,
    showErrorPage: !contactCollectionEnabled || !!error,
    tAdmin,
    isMutationLoading: createContactCollectionLoading || updateContactCollectionLoading,
    hasMutationError: !!createContactCollectionError || !!updateContactCollectionError
  };
};
