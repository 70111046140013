import React from 'react';
import { FormikProps } from 'formik';
import { Flex, TextV2, ButtonV2, FormControl, InputV2, SpacingStack, SelectV1, OptionType } from '@withjoy/joykit';
import IntlTelInput from 'react-intl-tel-input';
import { ContactInputFields, Context } from '../../ContactCollectorGuest.controller';
import { useFormController } from './Form.controller';
import { Wrapper, Details, Footer } from '../components';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions';
import { StyledFormControl } from './Form.styles';
import 'react-intl-tel-input/dist/main.css';

interface FormProps
  extends Readonly<{
    formik: FormikProps<ContactInputFields>;
    context: Context;
  }> {}

export const Form: React.FC<FormProps> = ({ formik, context: { coupleNames } }) => {
  const {
    tAdmin,
    handleOnSaveDetailsClick,
    getFieldProps,
    isFormValid,
    errors: { firstNameError, lastNameError, emailError, address1Error, postalCodeError, cityError, regionError, countryError },
    countryList,
    handleOnCountryDropdownChange,
    handleOnPhoneNumberChange,
    handleOnPhoneNumberFocus,
    isPhoneNumberInvalid
  } = useFormController(formik, coupleNames);

  return (
    <Wrapper>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX={['24px', null, 0]} width="100%">
        <TextV2 typographyVariant="hed6" marginTop={['24px', null, '4px']} textAlign="center">
          {tAdmin.addYourInfo()}
        </TextV2>
        <Details coupleNames={coupleNames} />
        <form style={{ width: '100%' }}>
          <SpacingStack spacing={6}>
            <TextV2 typographyVariant="hed4" marginTop="2rem" width="100%">
              {tAdmin.contactInfo()}
            </TextV2>
            <FormControl label={tAdmin.firstName()} isInvalid={!!firstNameError} error={firstNameError} isRequired>
              <InputV2 {...getFieldProps('firstName')} autoComplete="given-name" />
            </FormControl>
            <FormControl label={tAdmin.lastName()} isInvalid={!!lastNameError} error={lastNameError} isRequired>
              <InputV2 {...getFieldProps('lastName')} autoComplete="family-name" />
            </FormControl>
            <StyledFormControl label={tAdmin.phoneNumber()} isInvalid={isPhoneNumberInvalid} error={isPhoneNumberInvalid ? tAdmin.invalidPhoneNumber() : ''}>
              <IntlTelInput
                {...getFieldProps('phoneNumber')}
                autoComplete="tel"
                preferredCountries={['us']}
                onPhoneNumberChange={handleOnPhoneNumberChange}
                onPhoneNumberFocus={handleOnPhoneNumberFocus}
              />
            </StyledFormControl>
            <FormControl label={tAdmin.email()} isInvalid={!!emailError} error={emailError} isRequired>
              <InputV2 {...getFieldProps('email')} placeholder="your@email.com" autoComplete="email" />
            </FormControl>
            <TextV2 typographyVariant="hed4" marginTop="3rem !important" width="100%">
              {tAdmin.mailingAddress()}
            </TextV2>
            <FormControl label={tAdmin.address1()} isInvalid={!!address1Error} error={address1Error} isRequired>
              <InputV2 {...getFieldProps('address1')} autoComplete="address-line1" />
            </FormControl>
            <FormControl label={tAdmin.address2()}>
              <InputV2 {...getFieldProps('address2')} autoComplete="address-line2" />
            </FormControl>
            <FormControl label={tAdmin.city()} isInvalid={!!cityError} error={cityError} isRequired>
              <InputV2 {...getFieldProps('city')} autoComplete="address-level2" />
            </FormControl>
            <FormControl label={tAdmin.region()} isInvalid={!!regionError} error={regionError} isRequired>
              <InputV2 {...getFieldProps('region')} autoComplete="address-level1" />
            </FormControl>
            <FormControl label={tAdmin.postalCode()} isInvalid={!!postalCodeError} error={postalCodeError} isRequired>
              <InputV2 {...getFieldProps('postalCode')} autoComplete="postal-code" />
            </FormControl>
            <FormControl label={tAdmin.country()} isInvalid={!!countryError} error={countryError}>
              <SpacingStack spacing={3}>
                <SelectV1
                  options={countryList}
                  value={{ label: formik.values.country, value: formik.values.countryCode } as OptionType}
                  searchable={false}
                  onChange={handleOnCountryDropdownChange}
                />
              </SpacingStack>
            </FormControl>
          </SpacingStack>
          <TermsAndConditions />
          <ButtonV2 marginTop="32px" onClick={handleOnSaveDetailsClick} type="submit" fullWidth disabled={!isFormValid || isPhoneNumberInvalid}>
            {tAdmin.saveDetails()}
          </ButtonV2>
        </form>
        <Footer />
      </Flex>
    </Wrapper>
  );
};
