import React from 'react';
import { FormikProps } from 'formik';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { Desktop, Mobile } from './components';
import { VisualType } from '../Editor/components/Switch/Switch';
import { ANIMATIONS } from '../Editor/components/AnimationPicker/AnimationPicker';

interface PreviewProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    hideInstructions?: boolean;
  }> {}

export const getImageSource = (mediaType: VisualType, mediaSource: string) => {
  if (mediaType === VisualType.ANIMATION) {
    const animation = ANIMATIONS.find(({ id }) => id === mediaSource);
    return animation?.image;
  }
  return mediaSource;
};

export const Preview: React.FC<PreviewProps> = ({ formik, hideInstructions = false }) => {
  const isMobile = useIsMobileScreen();

  return isMobile ? <Mobile formik={formik} hideInstructions={hideInstructions} /> : <Desktop formik={formik} hideInstructions={hideInstructions} />;
};
