import React from 'react';
import { FormikProps } from 'formik';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { Flex, TextV2 } from '@withjoy/joykit';
import { Instructions } from '../Instructions/Instructions';
import { DOMAIN_NAME } from '@apps/contactCollector/routes/Manage/components/YourMagicLink/YourMagicLink.controller';
import { getImageSource } from '../../Preview';

interface MobileProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    hideInstructions?: boolean;
  }> {}

export const Mobile: React.FC<MobileProps> = ({ formik, hideInstructions = false }) => {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Flex alignItems="center" flexDirection="column" backgroundColor="#E9E9EB" borderRadius="19px">
        <img alt="source" src={getImageSource(formik.values.mediaType, formik.values.mediaSource)} width="290px" height="100%" style={{ borderRadius: '19px 19px 0 0' }}></img>
        <Flex textOverflow="ellipsis" wordBreak="break-word" width="290px" flexDirection="column" padding="10px">
          <TextV2 fontSize="14px" color="black" fontWeight={600} lineHeight={1.3} letterSpacing="-0.02em" fontFamily='-apple-system, system-ui, BlinkMacSystemFont, "Inter UI"'>
            {formik.values.message}
          </TextV2>
          <TextV2 fontSize="11px" color="mono10" fontWeight={500} letterSpacing="-0.02em" fontFamily='-apple-system, system-ui, BlinkMacSystemFont, "Inter UI"'>
            {DOMAIN_NAME}
          </TextV2>
        </Flex>
      </Flex>
      {!hideInstructions && <Instructions parentMarginTop="16px" childFlexDirection="column" childColumnGap={0} childRowGap="24px" iconSize="lg" />}
    </Flex>
  );
};
