import React from 'react';
import { FormikProps } from 'formik';
import { Flex, TextV2, IconV2 } from '@withjoy/joykit';
import { CheckOutline } from '@withjoy/joykit/icons';
import { ContactInputFields } from '@apps/contactCollector/guest/ContactCollectorGuest.controller';
import { SuggestedAddress, AddressOption } from '../../Address.controller';
import { useTranslation } from '@shared/core';
import { StyledFlex } from './Selector.styles';

interface SelectorProps
  extends Readonly<{
    formik: FormikProps<ContactInputFields>;
    suggestedAddress?: SuggestedAddress;
    addressOption: AddressOption;
    setAddressOption: React.Dispatch<React.SetStateAction<AddressOption>>;
  }> {}

export const Selector: React.FC<SelectorProps> = ({ formik, suggestedAddress, addressOption, setAddressOption }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex flexDirection="column" justifyContent="flex-start" width="100%">
      <TextV2 marginBottom="8px" marginTop="32px" fontWeight={700}>
        {tAdmin.originalAddress()}
      </TextV2>
      <Flex
        justifyContent="space-between"
        width="100%"
        paddingTop="12px"
        paddingBottom="24px"
        paddingRight="12px"
        paddingLeft="24px"
        borderRadius="8px"
        border={addressOption === 'original' ? '2px solid #5F2698' : '1px solid #EBEBEB'}
        onClick={() => setAddressOption('original')}
        role="button"
        cursor="pointer"
      >
        <Flex flexDirection="column" justifyContent="flex-start" paddingTop="12px" paddingRight="4px">
          <TextV2>{`${formik.values.firstName} ${formik.values.lastName}`}</TextV2>
          <TextV2>{formik.values.address1}</TextV2>
          {formik.values.address2 && <TextV2>{formik.values.address2}</TextV2>}
          <TextV2>{`${formik.values.city} ${formik.values.region}, ${formik.values.postalCode}`}</TextV2>
        </Flex>
        <IconV2 color="purple8" size="md" visibility={addressOption === 'original' ? 'initial' : 'hidden'}>
          <CheckOutline />
        </IconV2>
      </Flex>
      <TextV2 marginBottom="8px" marginTop="32px" fontWeight={700}>
        {tAdmin.suggestedAddress()}
      </TextV2>
      <StyledFlex
        justifyContent="space-between"
        width="100%"
        paddingTop="12px"
        paddingBottom="24px"
        paddingRight="12px"
        paddingLeft="24px"
        borderRadius="8px"
        border={addressOption === 'suggested' ? '2px solid #5F2698' : '1px solid #EBEBEB'}
        onClick={() => setAddressOption('suggested')}
        role="button"
        cursor="pointer"
        $disabled={!!!suggestedAddress}
      >
        <Flex flexDirection="column" justifyContent="flex-start" paddingTop="12px" paddingRight="4px">
          {suggestedAddress ? (
            <>
              <TextV2>{`${formik.values.firstName} ${formik.values.lastName}`}</TextV2>
              <TextV2>{suggestedAddress.address}</TextV2>
              {formik.values.address2 && <TextV2>{formik.values.address2}</TextV2>}
              <TextV2>{`${suggestedAddress.city}, ${suggestedAddress.state} ${suggestedAddress.zipCode}`}</TextV2>
            </>
          ) : (
            <TextV2>{tAdmin.addressValidationError()}</TextV2>
          )}
        </Flex>
        <IconV2 color="purple8" size="md" visibility={addressOption === 'suggested' ? 'initial' : 'hidden'}>
          <CheckOutline />
        </IconV2>
      </StyledFlex>
    </Flex>
  );
};
