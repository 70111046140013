import React from 'react';
import { HeartLock } from '@withjoy/joykit/icons';
import { Flex, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';

interface DetailsProps
  extends Readonly<{
    coupleNames: string;
  }> {}

export const Details: React.FC<DetailsProps> = ({ coupleNames }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex alignItems="center" justifyContent="center" marginTop="16px" paddingY="16px" paddingX="24px" backgroundColor="mono2" borderRadius="8px" width="100%">
      <HeartLock marginRight="24px" size="md" />
      <TextV2 typographyVariant="body1" marginY={0}>
        {tAdmin.landingPageMessage({ names: coupleNames })}
      </TextV2>
    </Flex>
  );
};
