import React from 'react';
import { FormikProps } from 'formik';
import { Flex, TextV2 } from '@withjoy/joykit';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { BottomSheet, BottomSheetProvider } from '@shared/components/BottomSheet';
import { Editor, Preview, EditDesignDialog } from './components';
import { useCreateController } from './Create.controller';
import { ContactCollectionEventFragment } from '@graphql/generated';
import { pxToRem } from '@withjoy/joykit/theme';
import PrintCrossSell from '@apps/contactCollector/components/PrintCrossSell';
import { useFeatureValue } from '@shared/core/featureFlags';

interface CreateProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    eventHandle: string;
    eventByName: Maybe<ContactCollectionEventFragment>;
    isFirstRun: boolean;
    isMutationLoading: boolean;
    hasMutationError: boolean;
  }> {}

export const Create: React.FC<CreateProps> = ({ formik, eventHandle, eventByName, isFirstRun, isMutationLoading, hasMutationError }) => {
  const { value: contactCollectionPrintCrossSellEnabled } = useFeatureValue('contactCollectionPrintCrossSellEnabled');
  const { minHeight, maxHeight, views, openDialog, setOpenDialog, tAdmin } = useCreateController(isFirstRun);

  return (
    <BottomSheetProvider views={views} minHeight={minHeight} maxHeight={maxHeight}>
      <Flex paddingBottom={[pxToRem(112), null, 0]} width="100%">
        <Flex width="50%" display={['none', null, 'flex']} flexDirection="column" alignItems="center" paddingTop="32px">
          <Flex width="375px" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <TextV2 typographyVariant="display1" fontWeight={700}>
              {tAdmin.createTitle()}
            </TextV2>
            <TextV2 typographyVariant="body2" fontWeight={400} color="mono12">
              {tAdmin.createSubTitle()}
            </TextV2>
          </Flex>
          <Editor formik={formik} eventHandle={eventHandle} eventByName={eventByName} isMutationLoading={isMutationLoading} hasMutationError={hasMutationError} />
        </Flex>
        <Flex width={['100%', null, '50%']} paddingTop="32px" flexDirection="column" alignItems="center" paddingX={['16px', null, 0]} backgroundColor={['white', null, 'mono1']}>
          <TextV2 display={['flex', null, 'none']} typographyVariant="hed6" fontWeight={700} textAlign="center" paddingBottom="16px">
            {tAdmin.createTitle()}
          </TextV2>
          <Preview formik={formik} />
          {contactCollectionPrintCrossSellEnabled === 'on' && (
            <Flex maxWidth="500px" marginTop={7}>
              <PrintCrossSell />
            </Flex>
          )}
        </Flex>
        <BottomSheet disableNavigation />
        <EditDesignDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          formik={formik}
          eventHandle={eventHandle}
          eventByName={eventByName}
          isMutationLoading={isMutationLoading}
          hasMutationError={hasMutationError}
        />
      </Flex>
    </BottomSheetProvider>
  );
};
