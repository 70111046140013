import { Flex } from '@withjoy/joykit';
import React, { useState } from 'react';
import { Thumbnail } from './AnimationPicker.styles';

export interface IAnimation {
  id: string;
  thumbnail: string;
  image: string;
}

interface AnimationPickerProps {
  handleSelect: (animationId: string) => void;
  imageSource: string;
}

export const ANIMATIONS: IAnimation[] = [
  {
    id: 'doodles',
    thumbnail: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/doodles_thumb_square.jpg',
    image: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/doodles.gif'
  },
  {
    id: 'paper-plane',
    thumbnail: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/paper-plane_thumb_square.jpg',
    image: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/paper-plane.gif'
  },
  {
    id: 'falling-envelopes',
    thumbnail: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/falling-envelopes_thumb_square.jpg',
    image: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/falling-envelopes.gif'
  },
  {
    id: 'mailbox',
    thumbnail: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/mailbox_thumb_square.jpg',
    image: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/mailbox.gif'
  },
  {
    id: 'hooray',
    thumbnail: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/hooray_thumb_square.jpg',
    image: 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/hooray.gif'
  }
];

const getInitialAnimation = (imageSource: string): IAnimation | undefined => ANIMATIONS.find(({ id }) => id === imageSource);

export const AnimationPicker: React.FC<AnimationPickerProps> = ({ handleSelect, imageSource }) => {
  const [selectedAnimation, setSelectedAnimation] = useState<IAnimation | undefined>(getInitialAnimation(imageSource));

  const handleAnimationSelectInternal = (animation: IAnimation) => {
    setSelectedAnimation(animation);
    handleSelect(animation.id);
  };

  return (
    <Flex marginTop="16px" width="100%" justifyContent="space-evenly">
      {ANIMATIONS.map(animation => (
        <Thumbnail
          key={animation.id}
          width={64}
          height={64}
          image={animation.thumbnail}
          isActive={selectedAnimation?.id === animation.id}
          onClick={() => {
            handleAnimationSelectInternal(animation);
          }}
        />
      ))}
    </Flex>
  );
};
