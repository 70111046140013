import React from 'react';
import { Flex, TextV2, IconV2, ButtonV2, Box } from '@withjoy/joykit';
import { DesignEmail } from '@withjoy/joykit/icons';
import { outlinedDarkButtonOverrides } from '@apps/postOffice/components/Shared.styles';
import { useTranslation } from '@shared/core';

interface EditDesignViewProps
  extends Readonly<{
    handleEditDesign: () => void;
    isFirstRun: boolean;
  }> {}

export const EditDesignView: React.FC<EditDesignViewProps> = ({ handleEditDesign, isFirstRun }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex flexDirection="column" padding="24px" paddingBottom={0} height="100%">
      <TextV2 marginBottom="8px" typographyVariant="hed4" color="mono14" display="flex" alignItems="center" justifyContent="center">
        <IconV2 size="md" marginRight="12px">
          <DesignEmail />
        </IconV2>{' '}
        {tAdmin.design()}
      </TextV2>
      <TextV2 textAlign="center" typographyVariant="body1" color="mono12" flex="1">
        {tAdmin.customize()}
      </TextV2>
      <Box marginY="24px">
        <ButtonV2 variant={isFirstRun ? 'solid' : 'outline'} overrides={isFirstRun ? undefined : outlinedDarkButtonOverrides} onClick={handleEditDesign} width="100%">
          {isFirstRun ? tAdmin.getStarted() : tAdmin.editDesign()}
        </ButtonV2>
      </Box>
    </Flex>
  );
};
