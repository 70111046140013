import React from 'react';
import { FormikProps } from 'formik';
import { ContactInputFields } from '../../ContactCollectorGuest.controller';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import { Wrapper, Footer } from '../components';
import { useAddressController } from './Address.controller';
import { Selector } from './components/Selector/Selector';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

interface AddressProps
  extends Readonly<{
    formik: FormikProps<ContactInputFields>;
  }> {}

export const Address: React.FC<AddressProps> = ({ formik }) => {
  const { isValidatingAddress, addressOption, setAddressOption, suggestedAddress, tAdmin, handleOnConfirmAddressClick, handleOnBackClick } = useAddressController(formik);

  if (isValidatingAddress) return <JoyLogoLoader loaderKey="contact-collector-address" />;

  return (
    <Wrapper>
      <Flex flexDirection="column" alignItems="center" justifyContent="center" paddingX={['24px', null, 0]} width="100%">
        <TextV2 typographyVariant="hed6" marginTop={['24px', null, '4px']} textAlign="center">
          {tAdmin.addressSuggestion()}
        </TextV2>
        <TextV2 typographyVariant="body1" marginTop="32px" width="100%">
          {tAdmin.addressSuggestionText1()}
        </TextV2>
        <TextV2 typographyVariant="body1" marginTop="32px" width="100%">
          {tAdmin.addressSuggestionText2()}
        </TextV2>
        <Selector formik={formik} suggestedAddress={suggestedAddress} addressOption={addressOption} setAddressOption={setAddressOption} />
        <ButtonV2 marginTop="32px" onClick={handleOnConfirmAddressClick} fullWidth>
          {tAdmin.confirmAddress()}
        </ButtonV2>
        <ButtonV2 marginTop="16px" variant="ghost" color="mono14" onClick={handleOnBackClick} fullWidth>
          {tAdmin.back()}
        </ButtonV2>
        <Footer />
      </Flex>
    </Wrapper>
  );
};
