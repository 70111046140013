import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useContactCollectorGuestTelemetryTelemetry } from '../../ContactCollectorGuest.telemetry';
import { contactCollectorGuestRoutePaths } from '../..';
import { ContactInputFields } from '../../ContactCollectorGuest.controller';

export const useConfirmationController = (formik: FormikProps<ContactInputFields>) => {
  const { buttonInteracted } = useContactCollectorGuestTelemetryTelemetry();
  const routeHelpers = useRouterHelper();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  useEffect(() => {
    if (!formik.isValid) {
      return routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.landing.path);
    }
    formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnModifyDetailsClick = () => {
    buttonInteracted({ label: 'ModifyDetails' });
    routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.form.path);
  };

  const handleOnLooksGoodClick = () => {
    buttonInteracted({ label: 'LooksGood' });
    routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.done.path);
  };

  return {
    tAdmin,
    handleOnModifyDetailsClick,
    handleOnLooksGoodClick
  };
};
