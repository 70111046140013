import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { useRouterHelper, useTranslation } from '@shared/core';
import { useContactCollectorGuestTelemetryTelemetry } from '../../ContactCollectorGuest.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { contactCollectorGuestRoutePaths } from '../..';
import { ContactInputFields } from '../../ContactCollectorGuest.controller';

export const useDoneController = (formik: FormikProps<ContactInputFields>, website: string) => {
  const isMobile = useIsMobileScreen();
  const routeHelpers = useRouterHelper();
  const { buttonInteracted } = useContactCollectorGuestTelemetryTelemetry();
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  useEffect(() => {
    if (!formik.isValid) routeHelpers.goToExactRoute(contactCollectorGuestRoutePaths.landing.path);
  }, [formik.isValid, routeHelpers]);

  const handleOnVisitEventWebsiteClick = () => {
    buttonInteracted({ label: 'VisitEventWebsite' });
    withWindow(() => {
      window.open(`${window.location.origin}/${website}`, '_blank');
    });
  };

  return {
    tAdmin,
    handleOnVisitEventWebsiteClick,
    isMobile
  };
};
