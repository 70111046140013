import { styled, FormControl } from '@withjoy/joykit';

export const StyledFormControl = styled(FormControl)`
  .intl-tel-input {
    width: 100%;
    .country-list {
      white-space: normal;
    }
  }
  input {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 0.25rem;
    height: 50px;
    box-shadow: ${props => (props.isInvalid ? '0px 0 0px 2px rgba(197,0,32,1)' : 'none')};
  }
  input:focus {
    outline: 0;
  }
`;
