import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { ApolloError } from '@apollo/client';

const category = 'admin.contactcollector';

const contactCollectorTelemetry = createTelemetryObject({
  actions: {
    trackError: (action: 'Query' | 'Mutation' | 'Other', error: ApolloError | Error | unknown) => ({
      category,
      action,
      actionType: 'error',
      extraInfo: { error }
    }),
    createOrUpdateMagicLinkClicked: ({
      eventHandle,
      visual,
      animation,
      message,
      linkToWebsite,
      displayDate,
      eventDate,
      operation
    }: {
      eventHandle: string;
      visual: string;
      animation: string;
      message: string;
      linkToWebsite: boolean;
      displayDate: boolean;
      eventDate: string;
      operation: 'create' | 'update';
    }) => ({
      category,
      action: `${operation === 'create' ? 'Create' : 'Update'}MagicLink`,
      label: eventHandle,
      actionType: 'click',
      extraInfo: {
        visual,
        animation,
        message,
        linkToWebsite,
        displayDate,
        eventDate
      }
    }),
    manageButtonsClicked: ({ label }: { label: string }) => ({
      category,
      action: 'ButtonInteracted',
      label,
      actionType: 'click'
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(contactCollectorTelemetry);
const useContactCollectorTelemetry = useTelemetry;
export { TelemetryProvider, useContactCollectorTelemetry };
