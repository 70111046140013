/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'People',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            d="M8.53723 11.4399C10.2284 10.9344 11.4611 9.36672 11.4611 7.51113C11.4611 5.24677 9.62545 3.41113 7.36108 3.41113C5.09671 3.41113 3.26108 5.24677 3.26108 7.51113C3.26108 9.3667 4.49374 10.9344 6.18487 11.4399C5.0175 11.6554 3.92827 12.1894 3.06124 12.9943C1.90605 14.0667 1.23693 15.5371 1.22269 17.0899L1.22266 19.0627C1.22266 19.483 1.40153 19.8719 1.69659 20.1482C1.98962 20.4226 2.37503 20.5673 2.76556 20.5673H11.9567C12.3472 20.5673 12.7326 20.4226 13.0256 20.1482C13.3207 19.8719 13.4996 19.483 13.4996 19.0627V17.1403C13.4996 15.5787 12.8365 14.0952 11.6796 13.0119C10.8097 12.1973 9.7132 11.657 8.53723 11.4399Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2611 7.51115C12.2611 5.24678 14.0967 3.41115 16.3611 3.41115C18.6255 3.41115 20.4611 5.24678 20.4611 7.51115C20.4611 9.37868 19.2125 10.9546 17.5045 11.4496C18.7763 11.6482 19.9653 12.2017 20.8997 13.0533C22.0918 14.1399 22.7735 15.6261 22.7735 17.189V19.1449C22.7735 19.5466 22.598 19.9192 22.3067 20.1847C22.0173 20.4484 21.6357 20.5882 21.2482 20.5882H14.6751C14.8861 20.2295 15.0217 19.8219 15.0589 19.3882H21.2482C21.3515 19.3882 21.4407 19.3503 21.4984 19.2977C21.5541 19.2469 21.5735 19.1905 21.5735 19.1449V17.189C21.5735 15.9822 21.048 14.8122 20.0913 13.9402C19.1328 13.0666 17.8217 12.5674 16.4444 12.5674C15.5069 12.5674 14.5999 12.7986 13.8139 13.222C13.5835 12.8899 13.3241 12.5753 13.038 12.2818C13.7222 11.8801 14.4833 11.6038 15.279 11.4668C13.5397 10.9921 12.2611 9.40095 12.2611 7.51115ZM16.3611 4.61115C14.7595 4.61115 13.4611 5.90952 13.4611 7.51115C13.4611 9.11277 14.7595 10.4111 16.3611 10.4111C17.9627 10.4111 19.2611 9.11277 19.2611 7.51115C19.2611 5.90952 17.9627 4.61115 16.3611 4.61115Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
