import React from 'react';
import { TextV2, LinkV2 } from '@withjoy/joykit';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useTranslation } from '@shared/core';

const TERMS_URL = 'https://withjoy.com/eula';
const PRIVACY_POLICY_URL = 'https://privacy.withjoy.com';

export const TermsAndConditions: React.FC = () => {
  const { value: contactCollectionMessagesTermsAndConditionsEnabled } = useFeatureValue('contactCollectionMessagesTermsAndConditionsEnabled');
  const { value: contactCollectionSmsAgreementVariant } = useFeatureValue('contactCollectionSmsAgreementVariant');
  const isVariant1 = contactCollectionSmsAgreementVariant === 'variant1';
  const isVariant2 = contactCollectionSmsAgreementVariant === 'variant2';
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  if (!contactCollectionMessagesTermsAndConditionsEnabled || (!isVariant1 && !isVariant2)) return null;

  return (
    <>
      {isVariant1 && (
        <TextV2 typographyVariant="label1" marginTop="16px" width="100%">
          {tAdmin.termsAndConditions1()}{' '}
          <LinkV2 typographyVariant="label1" href={TERMS_URL} textDecoration="none" target="_blank">
            {tAdmin.terms()}
          </LinkV2>{' '}
          {tAdmin.termsAndConditions2()}{' '}
          <LinkV2 typographyVariant="label1" href={PRIVACY_POLICY_URL} textDecoration="none" target="_blank">
            {tAdmin.privacyPolicy()}
          </LinkV2>{' '}
          {tAdmin.termsAndConditions3()}
        </TextV2>
      )}
      {isVariant2 && (
        <TextV2 typographyVariant="label1" marginTop="16px" width="100%">
          {tAdmin.termsAndConditions4()}{' '}
          <LinkV2 typographyVariant="label1" href={TERMS_URL} textDecoration="none" target="_blank">
            {tAdmin.terms()}
          </LinkV2>{' '}
          {tAdmin.termsAndConditions2()}{' '}
          <LinkV2 typographyVariant="label1" href={PRIVACY_POLICY_URL} textDecoration="none" target="_blank">
            {tAdmin.privacyPolicy()}.
          </LinkV2>
        </TextV2>
      )}
    </>
  );
};
