import { useMemo } from 'react';

import { monorepoRoutePaths, routePaths as adminRoutePaths } from '@apps/admin/route.paths';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { getProductVisibility } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { useRouterHelper, useTranslation } from '@shared/core';
import { GuestList, MailingAddress, RsvpAdmin } from '@withjoy/joykit/icons';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { PageInfo } from './useProductRows';

function getGuestListRsvpEnablementAndVisibility(eventPages: PageInfo[], productVerticals: ProductVerticals) {
  const rsvpPage = eventPages.find(({ pageSlug }) => pageSlug === EventPageSlugs.Rsvp);
  const visibility = getProductVisibility(rsvpPage?.visibility);
  const isEnabled: boolean = !productVerticals.optOutOfGuestList;

  return {
    isEnabled,
    visibility,
    pageId: rsvpPage?.id
  };
}

export const useGuestListRsvpProductRow = (eventPages: PageInfo[], productVerticals: ProductVerticals, eventId?: string): ProductRow => {
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  const { t2 } = useTranslation('adminDashboardV2');
  const visibilityTranslations = t2('visibility');
  const { guestList: guestListTranslations } = t2('sections');
  const { value: contactCollectionEnabled } = useFeatureValue('contactCollectionEnabled');

  const guestListRsvpProductRow = useMemo<ProductRow>(() => {
    const guestListStrings = guestListTranslations;
    const visibilityStrings = visibilityTranslations;
    const { isEnabled, visibility, pageId } = getGuestListRsvpEnablementAndVisibility(eventPages, productVerticals);

    const children = [
      {
        title: guestListStrings.children.guestList.title,
        icon: GuestList,
        path: routeHelpers.buildPath(monorepoRoutePaths.guests),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'guestList', section: 'Guest List', categoryArg }),
        telemetryName: monorepoRoutePaths.guests
      },
      {
        title: guestListStrings.children.contactCollector.title,
        icon: MailingAddress,
        path: routeHelpers.buildPath(adminRoutePaths.contact.path),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'contactCollector', section: 'Contact Collector', categoryArg }),
        hasNewPill: true,
        pathIsInJoyWeb: true,
        telemetryName: adminRoutePaths.contact.path
      },
      {
        title: guestListStrings.children.rsvp.title,
        icon: RsvpAdmin,
        path: routeHelpers.buildPath(monorepoRoutePaths.rsvp),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'rsvp', section: 'Guest List', categoryArg }),
        telemetryName: monorepoRoutePaths.rsvp
      }
    ];

    return {
      title: guestListStrings.title,
      stringOn: visibilityStrings.rsvpOn,
      stringOff: visibilityStrings.rsvpOff,
      settingsUrl: routeHelpers.buildPath(monorepoRoutePaths.rsvpSettings),
      showVisibilityDialog: true,
      isEnabled,
      visibility,
      onDialogOpen: () => telemetry.visibilityDialog.enter('rsvpVisibilityDialog'),
      onDialogUpdate: ({ visibility, visibilityChanged, eventPasswordChanged }) =>
        telemetry.visibilityUpdated({ label: 'rsvp', visibility, visibilityChanged, eventPasswordChanged }),
      pageId,
      children: !!contactCollectionEnabled ? children : children.filter(({ title }) => title !== guestListStrings.children.contactCollector.title)
    };
  }, [eventPages, guestListTranslations, routeHelpers, telemetry, visibilityTranslations, productVerticals, contactCollectionEnabled]);

  return guestListRsvpProductRow;
};
