import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { ChatDouble, MailingAddress, GuestList } from '@withjoy/joykit/icons';
import { IconSize } from '@withjoy/joykit/components/IconV2/Icon.types';
import { ResponsiveValue } from '@withjoy/joykit/styled-system';
import type { Property } from 'csstype';
import { useTranslation } from '@shared/core';

interface InstructionsProps
  extends Readonly<{
    parentMarginTop: string | number;
    childFlexDirection: ResponsiveValue<Property.FlexDirection>;
    childColumnGap: ResponsiveValue<Property.GridRowGap<string | number>>;
    childRowGap: ResponsiveValue<Property.GridRowGap<string | number>>;
    iconSize: IconSize | number;
  }> {}

export const Instructions: React.FC<InstructionsProps> = ({ parentMarginTop, childFlexDirection, childColumnGap, childRowGap, iconSize }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" marginTop={parentMarginTop} marginBottom="16px">
      <img src="https://withjoy.com/assets/public/social_icons_big.png" alt="Social" style={{ height: '24px', width: '184px' }} />
      <TextV2 typographyVariant="hed3" color="mono12" marginTop="16px">
        {tAdmin.instructionsTitle()}
      </TextV2>
      <Flex alignItems="center" justifyContent="center" flexDirection={childFlexDirection} columnGap={childColumnGap} rowGap={childRowGap} marginTop="16px">
        <Flex alignItems="center" justifyContent="center" flexDirection="column" width="105px">
          <ChatDouble size={iconSize} color="mono12" />
          <TextV2 typographyVariant="hed1" color="mono10" textAlign="center">
            {tAdmin.instructionsFirst()}
          </TextV2>
        </Flex>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" width="105px">
          <MailingAddress size={iconSize} color="mono12" />
          <TextV2 typographyVariant="hed1" color="mono10" textAlign="center">
            {tAdmin.instructionsSecond()}
          </TextV2>
        </Flex>
        <Flex alignItems="center" justifyContent="center" flexDirection="column" width="105px">
          <GuestList size={iconSize} color="mono12" />
          <TextV2 typographyVariant="hed1" color="mono10" textAlign="center">
            {tAdmin.instructionsThird()}
          </TextV2>
        </Flex>
      </Flex>
    </Flex>
  );
};
