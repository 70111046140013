import { Flex, styled, TextV2 } from '@withjoy/joykit';
import { InterFonts } from '@shared/utils/fonts/interFonts';
import { Restart } from '@withjoy/joykit/icons';

export const RestartIcon = styled(Restart)`
  cursor: pointer;
`;

export const Label = styled.span`
  ${InterFonts.subhead}
`;

export const InputLengthLabel = styled.p`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono8};
  margin-top: 9px;
  text-align: end;
`;

export const ToggleTitle = styled(TextV2)`
  ${InterFonts.bodyTitle}
`;

export const ToggleWrapper = styled(Flex)`
  border: 1px solid ${props => props.theme.colors.mono5};
  border-radius: 4px;
`;

export const LinkContainer = styled.span<{ $extraMargin?: boolean }>`
  ${InterFonts.caption}
  color: ${props => props.theme.colors.mono12};
  margin-top: ${props => (props.$extraMargin ? '10' : '4')}px;
`;

export const Link = styled.a`
  color: ${props => props.theme.colors.primary8};

  &:hover {
    color: ${props => props.theme.colors.primary6};
  }
`;
