import React from 'react';
import { Flex, Box, TextV2, ButtonV2 } from '@withjoy/joykit';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useHistory } from '@shared/core';

const PrintCrossSell = () => {
  const { eventHandle } = useEventInfo();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/${eventHandle}/edit/card`);
  };

  return (
    <Flex
      flexDirection={['column', null, 'row']}
      paddingX={[8, null, 7]}
      paddingY={[7, null, 5]}
      borderRadius={12}
      gap={[6, null, 4]}
      alignItems="center"
      boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.07), 0px 7px 27px 0px rgba(44, 41, 37, 0.06)"
      backgroundColor="white"
    >
      <Box width={['100%', null, '175px']} flex="none">
        <img src="https://withjoy.com/media/print/contact-collector-print-cross-sell-smart-tip.png" alt="Print" width="100%" />
      </Box>
      <Flex flexDirection="column" gap={[8, null, 5]} alignItems="flex-start">
        <Flex flexDirection="column" gap={[4, null, 1]}>
          <TextV2 typographyVariant="label2" color="mono12" textAlign={['center', null, 'left']}>
            SMART TIP
          </TextV2>
          <TextV2 typographyVariant="hed2" textAlign={['center', null, 'left']}>
            Get free, effortless envelope addressing on your Save the Dates and Invitations.
          </TextV2>
        </Flex>
        <Box
          display={['none', null, 'block']}
          cursor="pointer"
          role="button"
          typographyVariant="button1"
          borderBottom="1px solid #D9D9D9"
          _hover={{ borderBottomColor: 'transparent' }}
          onClick={handleClick}
        >
          Browse Card Designs
        </Box>
        <ButtonV2 onClick={handleClick} display={['flex', null, 'none']} width="100%" variant="outline" intent="neutral" shape="rounded">
          Browse Card Designs
        </ButtonV2>
      </Flex>
    </Flex>
  );
};

export default PrintCrossSell;
