import React from 'react';
import { Calendar } from '@withjoy/joykit/icons';
import { TextV2, Flex } from '@withjoy/joykit';
import { ToggleWrapper } from '../../Editor.styles';
import { useTranslation } from '@shared/core';

interface DateProps
  extends Readonly<{
    date: string;
  }> {}

export const Date: React.FC<DateProps> = ({ date }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex flexDirection="column" alignItems="flex-start" marginBottom="8px">
      <TextV2 color="mono14" typographyVariant="label3">
        {tAdmin.eventDate()}
      </TextV2>
      <ToggleWrapper marginTop="8px" paddingY="12px" paddingX="16px" alignItems="center">
        <Calendar marginRight="8px" />
        <TextV2 color="mono14" typographyVariant="label3">
          {date}
        </TextV2>
      </ToggleWrapper>
    </Flex>
  );
};
