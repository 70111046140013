import React, { useState, useEffect } from 'react';
import { BottomSheetView, NonEmptyArray } from '@shared/components/BottomSheet/context/BottomSheet.provider';
import { EditDesignView } from './components';
import { useTranslation } from '@shared/core';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useNativeState } from '@shared/core/native';

export const MIN_HEIGHT = 80;
const MAX_HEIGHT = 176;

export const useCreateController = (isFirstRun: boolean) => {
  const { t } = useTranslation('contactCollector');
  const { getValueString } = useQueryParamHelper();
  const { isNative } = useNativeState();
  const isEditingNative = getValueString('modify') === 'true' && isNative;
  const tAdmin = t('admin');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (isEditingNative) {
      setOpenDialog(true);
    }
  }, [isEditingNative]);

  const views: NonEmptyArray<BottomSheetView> = [{ id: 'design', children: <EditDesignView isFirstRun={isFirstRun} handleEditDesign={() => setOpenDialog(true)} /> }];

  return {
    minHeight: MIN_HEIGHT,
    maxHeight: MAX_HEIGHT,
    views,
    openDialog,
    setOpenDialog,
    tAdmin
  };
};
