import React from 'react';
import { FormikProps } from 'formik';
import { ContactCollectorInputFields } from '@apps/contactCollector/ContactCollector.controller';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { Instructions } from '../Instructions/Instructions';
import { DOMAIN_NAME } from '@apps/contactCollector/routes/Manage/components/YourMagicLink/YourMagicLink.controller';
import { getImageSource } from '../../Preview';
import { VisualType } from '../../../Editor/components/Switch/Switch';

interface DesktopProps
  extends Readonly<{
    formik: FormikProps<ContactCollectorInputFields>;
    hideInstructions?: boolean;
  }> {}

const IPHONE_FRAME = 'https://joy-public-assets-bucket.s3.us-west-2.amazonaws.com/frame.svg';

export const Desktop: React.FC<DesktopProps> = ({ formik, hideInstructions = false }) => {
  const { t } = useTranslation('contactCollector');
  const tAdmin = t('admin');

  return (
    <Flex alignItems="center" flexDirection="column">
      <TextV2 typographyVariant="hed1" color="mono10">
        {tAdmin.textMessagePreview()}
      </TextV2>
      <Box marginTop="30px" height="500px">
        <img alt="iphone-frame" src={IPHONE_FRAME}></img>
        <Flex
          maxHeight="420px"
          overflowY={formik.values.mediaType === VisualType.IMAGE ? 'scroll' : 'hidden'}
          overflowX="hidden"
          zIndex={1}
          position="absolute"
          top="230px"
          right="calc(25% - 146px)"
          alignItems="center"
          flexDirection="column"
          backgroundColor="#E9E9EB"
          borderRadius="19px"
          width="290px"
        >
          <img
            alt="source"
            src={getImageSource(formik.values.mediaType, formik.values.mediaSource)}
            width="290px"
            height="100%"
            style={{ borderRadius: '19px 19px 0px 0px' }}
          ></img>
          <Flex textOverflow="ellipsis" wordBreak="break-word" width="290px" flexDirection="column" padding="10px">
            <TextV2 fontSize="14px" color="black" fontWeight={600} lineHeight={1.3} letterSpacing="-0.02em" fontFamily='-apple-system, system-ui, BlinkMacSystemFont, "Inter UI"'>
              {formik.values.message}
            </TextV2>
            <TextV2 fontSize="11px" color="mono10" fontWeight={500} letterSpacing="-0.02em" fontFamily='-apple-system, system-ui, BlinkMacSystemFont, "Inter UI"'>
              {DOMAIN_NAME}
            </TextV2>
          </Flex>
        </Flex>
      </Box>
      {!hideInstructions && <Instructions parentMarginTop="48px" childFlexDirection="row" childColumnGap="24px" childRowGap={0} iconSize="xl" />}
    </Flex>
  );
};
